import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './MultiSelect.scss';

export default class MultiSelect extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super();
    this.state = {
      value: props.value,
      options: this.getSortedOptions(props.options, props.value)
    };
  }

  onSetOption = (option) => {
    const { value } = this.state;
    const exists = _.includes(value, option.value);
    if (!exists) {
      this.setState({ value: this.state.value.concat(option.value) }, () => {
        this.applyChanges();
      });
    } else {
      const newOptions = _.filter(value, (singleValue) => singleValue !== option.value);
      this.setState({ value: newOptions }, () => {
        this.applyChanges();
      });
    }
  };

  getSortedOptions = (options, value) => {
    let sortedOptions = _.map(options, (option) => {
      const newOption = _.clone(option);
      newOption.checked = _.includes(value, newOption.value);
      return newOption;
    });
    sortedOptions = _.orderBy(sortedOptions, ['checked', 'label'], ['desc', 'asc']);
    return sortedOptions;
  };

  applyChanges = () => {
    const { value } = this.state;
    this.props.onChange(value);
  };

  renderOption = (option) => {
    const { value } = this.state;
    const checked = _.includes(value, option.value);
    return (
      <div key={option.value} onClick={() => this.onSetOption(option)}>
        <div className="optionMain">
          <div className="optionToggleContainer">
            <input
              checked={checked}
              name={option.label}
              type="checkbox"
              readOnly
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="optionLabel">
            {option.label}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { options } = this.state;
    return (
      <div className="MultiSelect">
        <div
          className="optionsContainer"
        >
          <div className="optionsList">
            {_.map(options, (option) => this.renderOption(option))}
          </div>
        </div>
      </div>
    );
  }
}
