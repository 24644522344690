export const FETCH_REPORTS = 'FETCH_REPORTS';
export const FETCH_WIDGET = 'FETCH_WIDGET';

export const fetchReports = (catalogs) => (dispatch) => {
  dispatch({
    type: FETCH_REPORTS,
    payload: catalogs
  });
};

export const fetchWidget = (widget) => (dispatch) => {
  dispatch({
    type: FETCH_WIDGET,
    payload: widget
  });
};
