import Immutable from 'immutable';

export const NUMBER_OF_SIDEBAR_ITEMS_TO_DISPLAY = 5;
export const HEARTBEAT_INTERVAL_MILLISECONDS = 3600000;

export const FRECUENCY_OPTIONS = Immutable.fromJS([
  {
    label: 'Hourly',
    value: 'hourly',
    options: []
  },
  {
    label: 'Daily',
    value: 'daily',
    options: [
      {
        label: 'Choose a time',
        value: ''
      },
      {
        label: '9:00am',
        value: 900
      },
      {
        label: '9:30am',
        value: 930
      },
      {
        label: '10:00am',
        value: 1000
      },
      {
        label: '10:30am',
        value: 1030
      },
      {
        label: '11:00am',
        value: 1100
      },
      {
        label: '11:30am',
        value: 1130
      },
      {
        label: '12:00pm',
        value: 1200
      },
      {
        label: '12:30pm',
        value: 1230
      },
      {
        label: '1:00pm',
        value: 1300
      },
      {
        label: '1:30pm',
        value: 1330
      },
      {
        label: '2:00pm',
        value: 1400
      },
      {
        label: '2:30pm',
        value: 1430
      },
      {
        label: '3:00pm',
        value: 1500
      },
      {
        label: '3:30pm',
        value: 1530
      },
      {
        label: '4:00pm',
        value: 1600
      },
      {
        label: '4:30pm',
        value: 1630
      },
      {
        label: '5:00pm',
        value: 1700
      },
      {
        label: '5:30pm',
        value: 1730
      },
      {
        label: '6:00pm',
        value: 1800
      }
    ]
  },
  {
    label: 'Weekly',
    value: 'weekly',
    options: [
      {
        label: 'Select day of the week',
        value: ''
      },
      {
        label: 'Sunday',
        value: 'sunday'
      },
      {
        label: 'Monday',
        value: 'monday'
      },
      {
        label: 'Tuesday',
        value: 'Tuesday'
      },
      {
        label: 'Wednesday',
        value: 'wednesday'
      },
      {
        label: 'Thursday',
        value: 'thursday'
      },
      {
        label: 'Friday',
        value: 'friday'
      },
      {
        label: 'Saturday',
        value: 'saturday'
      }
    ]
  },
  {
    label: 'Monthly',
    value: 'monthly',
    options: [
      {
        label: 'Select day of the month',
        value: ''
      },
      {
        label: 'Day 01',
        value: '01'
      },
      {
        label: 'Day 02',
        value: '02'
      },
      {
        label: 'Day 03',
        value: '03'
      },
      {
        label: 'Day 04',
        value: '04'
      },
      {
        label: 'Day 05',
        value: '05'
      },
      {
        label: 'Day 06',
        value: '06'
      },
      {
        label: 'Day 07',
        value: '07'
      },
      {
        label: 'Day 08',
        value: '08'
      },
      {
        label: 'Day 09',
        value: '09'
      },
      {
        label: 'Day 10',
        value: '10'
      },
      {
        label: 'Day 11',
        value: '11'
      },
      {
        label: 'Day 12',
        value: '12'
      },
      {
        label: 'Day 13',
        value: '13'
      },
      {
        label: 'Day 14',
        value: '14'
      },
      {
        label: 'Day 15',
        value: '15'
      },
      {
        label: 'Day 16',
        value: '16'
      },
      {
        label: 'Day 17',
        value: '17'
      },
      {
        label: 'Day 18',
        value: '18'
      },
      {
        label: 'Day 19',
        value: '19'
      },
      {
        label: 'Day 20',
        value: '20'
      },
      {
        label: 'Day 21',
        value: '21'
      },
      {
        label: 'Day 22',
        value: '22'
      },
      {
        label: 'Day 23',
        value: '23'
      },
      {
        label: 'Day 24',
        value: '24'
      },
      {
        label: 'Day 25',
        value: '25'
      },
      {
        label: 'Day 26',
        value: '26'
      },
      {
        label: 'Day 27',
        value: '27'
      },
      {
        label: 'Day 28',
        value: '28'
      },
      {
        label: 'Day 29',
        value: '29'
      },
      {
        label: 'Day 30',
        value: '30'
      },
      {
        label: 'Day 31',
        value: '31'
      },
      {
        label: 'Last day of month',
        value: 'last'
      }
    ]
  }
]);

export const DATE_RANGE_LABELS = [
  'Yesterday',
  'This Month',
  'This Year',
  'This Quarter',
  'First Day of This Quarter',
  'First Day of Last Quarter',
  'Last Day of Last Quarter',
  'Last 3 Days',
  'Last 7 Days',
  'Last 30 Days',
  'Last Month',
  'Last Quarter',
  'Last 3 Months',
  'Last 6 Months',
  'Last 12 Months',
  'Last Year',
  'All Time'
];

export const TIME_RANGE_LABELS = {
  900: '9:00 am',
  1000: '10:00 am',
  1100: '11:00 am',
  1200: '12:00 pm',
  1300: '1:00 pm',
  1400: '2:00 pm',
  1500: '3:00 pm',
  1600: '4:00 pm',
  1700: '5:00 pm'
};

export const HOURLY_START_TIME = 900;
export const HOURLY_END_TIME = 1700;
export const SMALL_SCREEN_WIDTH = 767;

export const OPERATOR_LABELS = {
  Equal: 'Is Equal to',
  NotEqual: 'Is Not equal to',
  Greater: 'Is Greater than',
  GreaterOrEqual: 'Is Greater than or equal to',
  Less: 'Is Less than',
  LessOrEqual: 'Is Less than or equal to',
  Between: 'Is Between',
  NotBetween: 'Is Not Between'
};

export const DIMENSION_OPERATOR_LABELS = {
  Equal: 'Is Equal to',
  NotEqual: 'Is Not equal to',
  Like: 'Contains',
  NotLike: 'Does not Contains'
};

export const INLINE_ALERT_TYPE = 'inline';
export const TOTAL_ALERT_TYPE = 'total';

export const SEPARATOR = ' ';
