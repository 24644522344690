// import { OrderedSet, Map, List, Record, fromJS } from 'immutable';
import { Record, List, Map } from 'immutable';
import { INLINE_ALERT_TYPE
} from '../../components/Widgets/utils/catalogs';

export const Alert = new Record({
  id: undefined,
  metricId: undefined,
  value: undefined,
  triggered: undefined,
  title: undefined,
  description: undefined,
  filter: new Map(),
  alertMetric: new Map(),
  rowCount: undefined
});

export const Card = new Record({
  id: null,
  title: undefined,
  data: new Map(),
  chart_type: 'line',
  notification_group_ids: new List(),
  metric: new Map(),
  selectedColumns: new List(),
  metricLabel: undefined,
  metricType: undefined,
  description: undefined,
  filter: new Map(),
  metricOperator: undefined,
  reportId: undefined,
  reportViewId: undefined,
  parentReportName: undefined,
  metricThreshold: new List(['']),
  date_range: undefined,
  date_column: new Map(),
  alert_frequency: new Map(),
  enabled: true,
  metricAlerts: 0,
  metricLastRun: undefined,
  metricAlertCount: 0,
  alert_type: INLINE_ALERT_TYPE,
  widget: new Map(),
  broken: undefined
});

export const Notification = new Record({
  id: undefined,
  unread: undefined,
  message: undefined,
  time: undefined
});

export const NotificationGroup = new Record({
  id: undefined,
  groupName: undefined,
  emailAddresses: new List(),
  metrics: undefined,
  enabled: undefined
});

export const AlertNotification = new Record({
  id: undefined,
  unread: undefined,
  alertId: undefined,
  userId: undefined,
  triggered: undefined
});

export const DimensionColumn = new Record({
  dimension: undefined,
  dimensionOperator: undefined,
  dimensionThreshold: undefined,
  dimensionLabel: undefined,
  dimensionType: undefined
});
