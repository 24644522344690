export const FETCH_METRICS          = 'FETCH_METRICS';
export const DELETE_METRIC          = 'DELETE_METRIC';
export const ADD_METRIC             = 'ADD_METRIC';
export const FETCH_METRIC_DATA      = 'FETCH_METRIC_DATA';
export const SET_TEMP_FORM_STATUS   = 'SET_TEMP_FORM_STATUS';
export const SET_SHARED_METRICS          = 'SET_SHARED_METRICS';

export const fetchMetrics = (metrics) => (dispatch) => {
  dispatch({
    type: FETCH_METRICS,
    payload: metrics
  });
};
export const setSharedMetrics = (metrics) => (dispatch) => {
  dispatch({
    type: SET_SHARED_METRICS,
    payload: metrics
  });
};
export const deleteMetric = (id) => (dispatch) => {
  dispatch({
    type: DELETE_METRIC,
    payload: id
  });
};
export const addMetric = (metric) => (dispatch) => {
  dispatch({
    type: ADD_METRIC,
    payload: metric
  });
};
export const fetchMetricData = (alert_metric_histories) => (dispatch) => {
  dispatch({
    type: FETCH_METRIC_DATA,
    payload: alert_metric_histories
  });
};
export const setTempFormStatus = (status) => (dispatch) => {
  dispatch({
    type: SET_TEMP_FORM_STATUS,
    payload: status
  });
};
