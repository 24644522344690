import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import routes from '../routes';
import 'font-awesome/css/font-awesome.min.css';
import 'react-notification-alert/dist/animate.css';
import 'react-multi-email/style.css';
import './App.css';

class DebugRouter extends Router {
  constructor (props) {
    super(props);
    console.log('initial history is: ', JSON.stringify(this.history, null, 2))
    this.history.listen((location, action) => {
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      )
      console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null, 2));
    });
  }
}
/**
 * @class App
 * @extends {Component}
 */
class App extends React.PureComponent {
  static propTypes = {
    store: PropTypes.object.isRequired
  };

  render() {
    return (
      <Provider store={this.props.store}>
        <div className="App">
          <DebugRouter>{routes}</DebugRouter>
        </div>
      </Provider>
    );
  }
}

export default App;
