import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import './ConfirmationPopover.scss';

export default class ConfirmationPopover extends Component {
  static propTypes = {
    body: PropTypes.string.isRequired,
    children: PropTypes.string,
    title: PropTypes.string,
    cancel: PropTypes.func.isRequired,
    deleteitem: PropTypes.func.isRequired
  };

  static defaultProps = {
    children: null,
    title: null
  };

  onDeleteItem = (itemId) => {
    this.props.deleteitem(itemId);
  };

  onCancel = () => {
    this.props.cancel();
  };

  render() {
    return (
      <Popover
        placement="left"
        id="popover-trigger-focus"
        title={this.props.title}
      >
        <Row>
          {this.props.body}
          {this.props.children}
        </Row>
        <Form.Row className="ConfirmationPopover-ButtonsContainer">
          <Col className="confirmation-buttons">
            <Link
              to="#"
              onClick={this.onCancel}
              className="btn btn-cancel"
              role="button"
            >
              Cancel
            </Link>
            <Link
              to="#"
              onClick={this.onDeleteItem}
              className="btn btn-danger"
              role="button"
            >
              Delete
            </Link>
          </Col>
        </Form.Row>
      </Popover>
    );
  }
}
