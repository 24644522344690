export const SET_TOKENS = 'SET_TOKENS';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const LOGOUT_USER = 'LOGOUT_USER';

export const setTokens = (tokens) => (dispatch) => {
  dispatch({
    type: SET_TOKENS,
    payload: tokens
  });
};

export const fetchProfile = (profile) => (dispatch) => {
  dispatch({
    type: FETCH_PROFILE,
    payload: profile
  });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT_USER
  });
};
