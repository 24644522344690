import Immutable from 'immutable';
import { FETCH_ALERTS, SET_ALERTS } from '../actions/alertsActions';

const initialState = Immutable.fromJS({ alerts: [] });

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALERTS:
    case SET_ALERTS:
      return state.set('alerts', action.payload.get('alerts'));
    default:
      return state;
  }
};
