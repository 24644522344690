import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from '../pages/Main/Main';
import AlertManager from '../pages/AlertManager/AlertManager';
import Alerts from '../pages/Alerts/Alerts';
import AlertBuilder from '../pages/AlertBuilderPage/AlertBuilder';
import NotificationGroup from '../pages/NotificationGroup/NotificationGroup';
import NotificationGroupBuilder from '../pages/NotificationGroupBuilder/NotificationGroupBuilder';
import Message from '../pages/Message/Message';
import Login from '../pages/Login/Login';
import TestPage from '../pages/TestPage/TestPage';

const DEV_ENV = process.env.NODE_ENV === 'development';

export default (
  <Switch>
    {/* If path is / then load the Login component */}
    <Route exact path="/" component={Login} />
    <Route path="/home" component={Main} />
    <Route path="/AlertManager" component={AlertManager} />
    <Route
      path="/Alert/:apiToken/:csrfToken/:legacyApiUrl/:staqAppUrl/:alertId"
      component={Login}
    />
    <Route path="/Alerts/:alertId" component={Alerts} />
    <Route path="/Alerts" component={Alerts} />
    <Route path="/AlertBuilder" exact component={AlertBuilder} />
    <Route path="/AlertBuilder/widget/:widgetId" component={AlertBuilder} />
    <Route path="/AlertBuilder/:reportId" component={AlertBuilder} />
    <Route path="/NotificationGroup" component={NotificationGroup} />
    <Route
      path="/NotificationGroupBuilder"
      component={NotificationGroupBuilder}
    />
    <Route path="/Message" component={Message} />
    <Route
      path="/Login/:apiToken/:csrfToken/:legacyApiUrl/:staqAppUrl/widget/:widgetId"
      component={Login}
    />
    <Route
      path="/Login/:apiToken/:csrfToken/:legacyApiUrl/:staqAppUrl/:reportId"
      component={Login}
    />
    <Route
      path="/Login/:apiToken/:csrfToken/:legacyApiUrl/:staqAppUrl"
      component={Login}
    />
    {DEV_ENV ? <Route path="/test" component={TestPage} /> : null}
  </Switch>
);
