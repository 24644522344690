import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './Alerts.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import C3Chart from 'react-c3js';
import NotificationAlert from 'react-notification-alert';
import { Map, List } from 'immutable';
import numeral from 'numeraljs';
import moment from 'moment';
import Heartbeat from 'react-heartbeat';
import AlertCard from '../../components/AlertCard/AlertCard';
import Header from '../../components/Header/Header';
import Menu from '../../components/Menu/Menu';
import { fetchAlertNotifications } from '../../redux/actions/notificationsActions';

import {
  fetchMetrics,
  fetchMetricData,
  FETCH_METRIC_DATA
} from '../../redux/actions/metricsActions';
import {
  togglePageStatus,
  toggleResourceStatus
} from '../../redux/actions/viewActions';
import {
  HEARTBEAT_INTERVAL_MILLISECONDS,
  INLINE_ALERT_TYPE
} from '../../components/Widgets/utils/catalogs';

/*
 * mapDispatchToProps
 */
const mapDispatchToProps = (dispatch) => ({
  fetchMetrics: () => dispatch(fetchMetrics()),
  fetchAlertNotifications: () => dispatch(fetchAlertNotifications()),
  fetchMetricData: (id) => dispatch(fetchMetricData(id)),
  togglePageStatus: (id) => dispatch(togglePageStatus(id)),
  toggleResourceStatus:
    (pageName, resourceName) => dispatch(toggleResourceStatus(pageName, resourceName))
});

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  ...state
});

/**
 * @class Alerts
 * @extends {Component}
 */
class Alerts extends Component {
  static propTypes = {
    fetchAlertNotifications: PropTypes.func.isRequired,
    fetchMetrics: PropTypes.func.isRequired,
    fetchMetricData: PropTypes.func.isRequired,
    togglePageStatus: PropTypes.func.isRequired,
    toggleResourceStatus: PropTypes.func.isRequired,
    viewReducer: PropTypes.instanceOf(Map).isRequired,
    metricsReducer: PropTypes.instanceOf(Map).isRequired,
    alertsReducer: PropTypes.instanceOf(Map).isRequired,
    userProfileReducer: PropTypes.instanceOf(Map).isRequired,
    catalogsReducer: PropTypes.instanceOf(Map).isRequired
  };

  componentDidMount() {
    const {
      viewReducer,
      fetchMetrics,
      fetchAlertNotifications,
      togglePageStatus
    } = this.props;
    const pageName = this.isSelectedMetric() ? 'alertsHistorical' : 'alerts';
    if (!viewReducer.getIn(['view', 'pages', 'alerts', 'isResourcesReady'])) {
      fetchMetrics();
      fetchAlertNotifications();
    } else if (
      pageName === 'alertsHistorical'
        && !viewReducer.getIn(['view', 'pages', pageName, 'firstLoad'])
    ) {
      togglePageStatus(pageName);
      this.loadMetricData();
    }
    if (
      pageName === 'alerts'
        && !viewReducer.getIn(['view', 'pages', pageName, 'firstLoad'])
    ) {
      togglePageStatus(pageName);
    }
  }

  componentDidUpdate(prevProps) {
    const { viewReducer, togglePageStatus } = this.props;
    const pageName = this.isSelectedMetric() ? 'alertsHistorical' : 'alerts';
    if (
      pageName === 'alertsHistorical'
        && viewReducer.getIn(['view', 'pages', pageName, 'isResourcesReady'])
        && !viewReducer.getIn(['view', 'pages', pageName, 'firstLoad'])
    ) {
      const shouldLoadData = this.redirectInlineAlerts();
      if (shouldLoadData) {
        this.loadMetricData();
      }
      togglePageStatus(pageName);
    }
    if (
      this.props.viewReducer.getIn([
        'view',
        'pages',
        'main',
        'notifications',
        'count'
      ]) !== prevProps.viewReducer.getIn([
        'view',
        'pages',
        'main',
        'notifications',
        'count'
      ])
    ) {
      const options = {
        place: 'br',
        message: (
          <div>
            {this.props.viewReducer.getIn([
              'view',
              'pages',
              'main',
              'notifications',
              'message'
            ])}
          </div>
        ),
        type: 'danger',
        icon: 'now-ui-icons ui-1_bell-53',
        autoDismiss: 5
      };
      this.refs.notify.notificationAlert(options);
    }
  }

  componentWillUnmount() {
    const { viewReducer, togglePageStatus, toggleResourceStatus } = this.props;
    const pageName = this.isSelectedMetric() ? 'alertsHistorical' : 'alerts';
    if (
      pageName === 'alertsHistorical'
        && viewReducer.getIn(['view', 'pages', pageName, 'firstLoad'])
    ) {
      togglePageStatus(pageName);
      toggleResourceStatus(pageName, FETCH_METRIC_DATA);
    }
  }

  reloadDataSources = () => {
    const { fetchAlertNotifications } = this.props;
    fetchAlertNotifications();
    this.loadMetricData();
  };

  /* eslint-disable */
  isSelectedMetric = () => {
    return this.props.match.params && this.props.match.params.alertId
      ? this.props.match.params.alertId
      : false;
  };
  /* eslint-enable */

  loadMetricData = () => {
    const { fetchMetricData } = this.props;
    const selectedMetric = this.isSelectedMetric();
    if (selectedMetric) {
      fetchMetricData(selectedMetric);
    }
  };

  redirectInlineAlerts = () => {
    const { metricsReducer, userProfileReducer, catalogsReducer } = this.props;
    const selectedMetric = this.isSelectedMetric();
    const metrics = metricsReducer.get('cards');
    const staqAppUrl = userProfileReducer.get('profile').getIn(['tokens', 'staqAppUrl']);
    let metric = metrics.find((m) => m.get('id') == selectedMetric);
    let ownMetric = true;
    if (!metric) {
      const sharedMetric = catalogsReducer.get('sharedMetrics').find((m) => m.get('id') == selectedMetric);
      if (sharedMetric) {
        metric = sharedMetric;
        ownMetric = false;
      }
    }
    if (!metric) return null;
    const alertType = metric.get('alert_type');
    if (alertType === INLINE_ALERT_TYPE) {
      const filters = metric.get('filter');
      const filtersForURI = escape(encodeURIComponent(JSON.stringify(filters)));
      const redirectUrl = `${staqAppUrl}/report_viewer/report_views/${metric.get(
        'reportViewId'
      )}?filters=${filtersForURI}`;
      window.location.assign(redirectUrl);
    }
    return ownMetric;
  }

  renderChart(selectedMetric, alerts) {
    const metrics = this.props.metricsReducer.get('cards');
    const metricWithData = metrics.find(
      (m) => m.get('id').toString() === selectedMetric.toString()
    );
    if (
      !metricWithData
        || !metricWithData.has('data')
        || metricWithData.get('data').isEmpty()
    ) {
      console.log('No Historical Data is Available');
      return null;
    }
    let dates = new List();
    let data = new List();
    if (metricWithData && metricWithData.has('data')) {
      dates = metricWithData
        .get('data')
        .map((m) => moment(new Date(m.get('date'))).format('YYYY-MM-DD'));
      data = metricWithData.get('data').map((m) => m.get('metric'));
      dates = dates.insert(0, 'date');
      data = data.insert(0, metricWithData.get('metricLabel'));
    }
    // const threshold = metricWithData.get('metricThreshold');
    const isTwoValuesCondition = (metricWithData.get('metricOperator') === 'Between'
      || metricWithData.get('metricOperator') === 'NotBetween');
    let lines = [];
    if (isTwoValuesCondition) {
      const threshold = metricWithData.get('metricThreshold').get(0);
      const thresholdDown = metricWithData.get('metricThreshold').get(1);
      lines = [
        { value: threshold,
          text: `Threshold ${numeral(threshold).format('0,0.[00]')}`,
          class: 'region-orange' },
        { value: thresholdDown,
          text: `Threshold ${numeral(thresholdDown).format('0,0.[00]')}`,
          class: 'region-orange' }
      ];
    } else {
      const threshold = metricWithData.get('metricThreshold').first();
      lines = [
        { value: threshold,
          text: `Threshold ${numeral(threshold).format('0,0.[00]')}`,
          class: 'region-orange' }
      ];
    }

    const matches = alerts.map((alert) => ({
      value: moment(new Date(alert.get('triggered'))).format('YYYY-MM-DD'),
      text: '',
      class: 'label-200'
    }));
    return (
      <C3Chart
        style={{ height: '100%', marginTop: 20 }}
        data={{
          x: 'date',
          columns: [dates.toJS(), data.toJS()]
        }}
        grid={{
          y: {
            lines
          },
          x: {
            lines: matches.toJS()
          }
        }}
        axis={{
          x: {
            type: 'timeseries'
          },
          y: {
            tick: { format: (d) => numeral(d).format('0,0.[00]') }
          }
        }}
        point={{
          r: 6
        }}
      />
    );
  }

  render() {
    let metrics = this.props.metricsReducer.get('cards');
    const selectedMetric = this.isSelectedMetric();
    let selectedMetricMetadata = metrics.find(
      (m) => m.get('id').toString() === selectedMetric.toString()
    );
    if (!selectedMetricMetadata) {
      const sharedMetric = this.props.catalogsReducer.get('sharedMetrics').find((m) => m.get('id') == selectedMetric);
      if (sharedMetric) {
        selectedMetricMetadata = sharedMetric;
        metrics = this.props.catalogsReducer.get('sharedMetrics');
      }
    }
    const alerts = selectedMetric
      ? this.props.alertsReducer
        .get('alerts')
        .filter((a) => a.get('metricId').toString() === selectedMetric.toString())
      : this.props.alertsReducer.get('alerts');
    const d = new Date();
    let pageTitle = 'All Alerts';
    if (selectedMetricMetadata && selectedMetricMetadata.has('alert_frequency')) {
      pageTitle = selectedMetricMetadata.get('title');
      const frequency = selectedMetricMetadata.getIn(['alert_frequency', 'frequency']);
      switch (frequency) {
        case 'hourly':
        case 'daily':
          d.setDate(d.getDate() - 7);
          break;
        case 'weekly':
          d.setDate(d.getDate() - 49);
          break;
        case 'monthly':
          d.setMonth(d.getMonth() - 7);
          break;
        default:
          d.setYear(1970);
      }
    } else {
      d.setYear(1970);
    }
    const filtered_alerts = alerts.filter((alert) => new Date(alert.triggered) >= d);
    const sortedAlerts = filtered_alerts
      .sortBy((c) => c.get('triggered'))
      .reverse();
    const { viewReducer } = this.props;
    const pageName = this.isSelectedMetric() ? 'alertsHistorical' : 'alerts';
    const isResourcesReady = viewReducer.getIn([
      'view',
      'pages',
      pageName,
      'isResourcesReady'
    ]);
    let emptyAlertsMessage = !isResourcesReady ? (
      <Row className="Page-emptyAlertsMessage">
        <Col className="Page-loadingMessageContainer">
          <div className="Page-loadingMessage">Loading...</div>
        </Col>
      </Row>
    ) : null;
    emptyAlertsMessage = viewReducer.getIn(['view', 'pages', pageName, 'firstLoad'])
      && isResourcesReady
      && sortedAlerts.size < 1 ? (
        <Row className="Page-emptyAlertsMessage">
          <Col>
            <Jumbotron>
              <h2>
                <i className="fa fa-tachometer Page-IconLarge"></i>
              </h2>
              <p>You&apos;re All Caught Up On Alerts</p>
            </Jumbotron>
          </Col>
        </Row>
      ) : (
        emptyAlertsMessage
      );
    const FLUID = true;
    return (
      <Container fluid={FLUID}>
        <Heartbeat
          heartbeatFunction={this.reloadDataSources}
          heartbeatInterval={HEARTBEAT_INTERVAL_MILLISECONDS}
        />
        <Row>
          <Header profile={this.props.userProfileReducer.get('profile')} />
          <NotificationAlert ref="notify" />
        </Row>
        <Menu pageTitle={pageTitle} />
        <Row>
          <Col>
            <Container>
              {emptyAlertsMessage}
              <Row>
                <Col md={12} xl={12}>
                  <div className="Alerts-ChartContainer">
                    {selectedMetric
                      && isResourcesReady
                      && this.renderChart(selectedMetric, sortedAlerts)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12} xl={12}>
                  <Container>
                    <Row>
                      <Col md={12} xl={12}>
                        <Row>
                          {sortedAlerts.map((alert) => (
                            <Col key={alert.get('id')} md={12} xl={12}>
                              <AlertCard alert={alert} metrics={metrics} profile={this.props.userProfileReducer.get('profile')} />
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
