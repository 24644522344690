import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { middleware as webClientMiddleware } from './middleware/webClientMiddleware';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// eslint-disable-next-line
export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunk, webClientMiddleware))
  );
}
