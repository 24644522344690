import Immutable from 'immutable';
import { Alert, Card, NotificationGroup, AlertNotification, DimensionColumn } from '../records';

const normalizeFilters = (card) => {
  const filter = card.get('filter') instanceof Immutable.List
    ? card.get('filter')
    : new Immutable.List([card.get('filter')]);
  const normilizedFilter = filter.map((f) => {
    let newFilter = f;
    if (f.get('operator') !== 'Between' && f.get('operator') !== 'NotBetween') {
      const values = newFilter.get('values');
      if (values.constructor === Immutable.List) {
        newFilter = newFilter.set('values', [newFilter.get('values').first()]);
      } else {
        newFilter = newFilter.set('values', [newFilter.get('values')]);
      }
    }
    return newFilter;
  });
  return card.set('filter', normilizedFilter);
};

export const parseSingleMetric = (card) => {
  const reportId = card.getIn(['report', 'parent_report_view_id']) === null
    ? card.getIn(['report', 'id'])
    : card.getIn(['report', 'parent_report_view_id']);

  const dateFilter = Immutable.isMap(card.get('filter'))
    ? undefined
    : card.get('filter').find((e) => e.get('column') == 'date');
  const dateRange = dateFilter !== undefined
    ? dateFilter.getIn(['values', 0])
    : undefined;

  const dimensions = card.get('report_column_dimensions');
  const selectedColumns = dimensions !== undefined
    ? dimensions.map((dimension) => {
      const dimensionId = dimension.get('id');
      const filter = card.get('filter').find((e) => e.get('id') == dimensionId);
      const newDimension_column = dimension
        .set('dimension', dimensionId)
        .set('dimensionLabel', dimension.get('label'))
        .set('dimensionType', dimension.get('type'))
        .set('dimensionOperator', filter.get('operator'))
        .set('dimensionThreshold', filter.getIn(['values']));

      return new DimensionColumn(newDimension_column);
    })
    : undefined;

  const metricId = card.getIn(['report_column_metric', 'id']);
  const metricFilter = Immutable.isMap(card.get('filter'))
    ? card.get('filter')
    : card.get('filter').find((e) => e.get('id') == metricId);

  const newCard = metricFilter
    ? card
      .set('reportViewId', card.getIn(['report', 'id']))
      .set('reportId', reportId)
      .set('parentReportName', card.getIn(['parent_report_name']))
      .set('date_range', dateRange)
      .set('metric', metricId)
      .set('metricLabel', card.getIn(['report_column_metric', 'label']))
      .set('metricType', card.getIn(['report_column_metric', 'type']))
      .set('metricLastRun', card.getIn(['last_run_at']))
      .set('selectedColumns', selectedColumns)
      .set('metricThreshold', metricFilter.getIn(['values']))
      .set('metricOperator', metricFilter.get('operator'))
      .set('broken', card.get('broken'))
    : card
      .set('reportViewId', card.getIn(['report', 'id']))
      .set('reportId', reportId)
      .set('parentReportName', card.getIn(['parent_report_name']))
      .set('date_range', dateRange)
      .set('metric', metricId)
      .set('metricLabel', card.getIn(['report_column_metric', 'label']))
      .set('metricType', card.getIn(['report_column_metric', 'type']))
      .set('metricLastRun', card.getIn(['last_run_at']))
      .set('selectedColumns', selectedColumns)
      .set('broken', card.get('broken'));

  return new Card(normalizeFilters(newCard));
};

export const parseAlertsData = (response) => {
  const responseImmutable = Immutable.fromJS(response);
  const alerts = responseImmutable.get('alerts');
  const alert_metrics = responseImmutable.get('alert_metrics');
  const alert_notifications = responseImmutable
    .get('alert_notifications')
    .map((alert_notification) => {
      const alert = alerts.find(
        (singleAlert) => singleAlert.get('id') === alert_notification.get('alert_id')
      );
      const alert_metric = alert_metrics.find(
        (alertMetric) => alertMetric.get('id') === alert.getIn(['alert_metric', 'id'])
      );
      const value = alert.getIn(['metric_value']);
      const rowCount = alert.getIn(['row_count']);
      const title = alert.getIn(['alert_metric', 'title']);
      const alertId = alert.getIn(['id']);
      const alertMetric = parseSingleMetric(
        alert.get('alert_metric')
          .set('report_column_metric', alert_metric.getIn(['report_column_metric']))
          .set('report_column_dimensions', alert_metric.getIn(['report_column_dimensions']))
      );
      const newAlert_notification = alert_notification
        .set('id', alertId)
        .set('metricId', alert.getIn(['alert_metric', 'id']))
        .set('triggered', alert.getIn(['alert_at']))
        .set('filter', alert.getIn(['alert_metric', 'filter']))
        .set('alertMetric', alertMetric)
        .set('title', title)
        .set('description', alert.getIn(['alert_metric', 'description']))
        .set('value', value)
        .set('rowCount', rowCount);
      return new Alert(newAlert_notification);
    });
  return Immutable.fromJS({ alerts: alert_notifications });
};

export const parseMetricsData = (response) => {
  const cards = Immutable.fromJS(response)
    .get('alert_metrics')
    .map((card) => parseSingleMetric(card));
  return Immutable.fromJS({ cards });
};

export const parseAlertNotificationsData = (response) => {
  const alerts = Immutable.fromJS(response).get('alerts');
  const alert_notifications = Immutable.fromJS(response)
    .get('alert_notifications')
    .map((alert_notification) => {
      const newAlertNotification = alert_notification
        .set('alertId', alert_notification.getIn(['alert_id']))
        .set('userId', alert_notification.getIn(['user_id']))
        .set('unread', alert_notification.getIn(['unread']))
        .set('triggered', alerts.find(
          (a) => a.get('id') == alert_notification.get('alert_id')
        ).get('alert_at'))
      return new AlertNotification(newAlertNotification);
    });
  return Immutable.fromJS({ alert_notifications });
};

export const parseMetricsDataData = (response) => {
  const dataset = Immutable.fromJS(response)
    .get('alert_metric_histories')
    .map((item) => {
      const value = item.getIn(['metric_value']);
      return Immutable.fromJS({ date: item.get('created_at'), metric: value });
    });
  return dataset;
};

export const parseNotificationGroupsData = (response) => {
  const notification_groups = Immutable.fromJS(response)
    .get('notification_groups')
    .map((notification_group) => {
      const newNotification_group = notification_group
        .set('emailAddresses', notification_group.get('email_addresses'))
        .set('groupName', notification_group.get('title'));
      return new NotificationGroup(newNotification_group);
    });
  return Immutable.fromJS({ notification_groups });
};

export const parseNotificationGroupAddData = (response) => {
  const notification_group = Immutable.fromJS(response).get(
    'notification_group'
  );
  const newNotification_group = notification_group
    .set('emailAddresses', notification_group.get('email_addresses'))
    .set('groupName', notification_group.get('title'));
  return new NotificationGroup(newNotification_group);
};

export const parseMetricsAddData = (response) => {
  const alert_metric = Immutable.fromJS(response).get('alert_metric');
  return parseSingleMetric(alert_metric);
};
