import Immutable from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './NotificationGroupBuilder.scss';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import Header from '../../components/Header/Header';
import Menu from '../../components/Menu/Menu';
import { addNotificationGroup } from '../../redux/actions/notificationGroupsActions';
import { NotificationGroup } from '../../redux/records';

/*
 * mapDispatchToProps
 */
const mapDispatchToProps = (dispatch) => ({
  addNotificationGroup: (notificationGroup) => dispatch(addNotificationGroup(notificationGroup))
});

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  ...state
});

const initialNotificationGroup = new NotificationGroup();

/**
 * @class NotificationGroupBuilder
 * @extends {Component}
 */
class NotificationGroupBuilder extends Component {
  static propTypes = {
    addNotificationGroup: PropTypes.func.isRequired,
    location: PropTypes.object,
    metricsReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    userProfileReducer: PropTypes.instanceOf(Immutable.Map).isRequired
  };

  static defaultProps = {
    location: null
  };

  constructor(props) {
    super(props);
    const notificationGroup = props.location.params && props.location.params.notificationGroup
      ? props.location.params.notificationGroup
      : initialNotificationGroup;
    this.state = {
      notificationGroup,
      validatedForm: false,
      isInvalidGroupName: false
    };
  }

  onAddNotificationGroup = (event) => {
    const groupName = this.refs.groupName.value;
    if (groupName.length > 0) {
      this.props.addNotificationGroup(this.state.notificationGroup);
    } else {
      event.preventDefault();
      event.stopPropagation();
      this.setState({
        validatedForm: true,
        isInvalidGroupName: true
      });
    }
  };

  handleChange = (event) => {
    const currentTargetValue = event.currentTarget.value;
    const isInvalidGroupName = currentTargetValue === '';
    this.setState({
      notificationGroup:
        this.state.notificationGroup.set(event.currentTarget.id, event.currentTarget.value),
      isInvalidGroupName
    })
  };

  render() {
    const { isInvalidGroupName, validatedForm, notificationGroup } = this.state;
    const tempStatus = this.props.metricsReducer.get('tempStatus');
    const linkPath = tempStatus ? '/AlertBuilder' : '/NotificationGroup';
    const { tempCard, tempSchema } = this.props.location;
    const FLUID = true;
    return (
      <Container fluid={FLUID} className="Page-Layout">
        <Row>
          <Header profile={this.props.userProfileReducer.get('profile')} />
        </Row>
        <Menu />
        <Row className="Page-Main">
          <Col>
            <Container>
              <Row>
                <Col align="center">
                  <Card className="NotificationGroupBuilder-Container">
                    <Card.Body>
                      <Form>
                        <Form.Group controlId="groupName" align="left">
                          <Form.Label>Notification Group name</Form.Label>
                          <Form.Control
                            ref="groupName"
                            type="text"
                            placeholder="Name"
                            value={notificationGroup.get('groupName') || ''}
                            onChange={this.handleChange}
                            isInvalid={validatedForm && isInvalidGroupName}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a group name.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="emailAddresses" align="left">
                          <Form.Label>Email Addresses</Form.Label>
                          <ReactMultiEmail
                            placeholder="Email Addresses"
                            emails={notificationGroup
                              .get('emailAddresses')
                              .toJS()}
                            onChange={(_emails: string[]) => {
                              this.setState({
                                notificationGroup: notificationGroup.set(
                                  'emailAddresses',
                                  Immutable.fromJS(_emails)
                                )
                              });
                            }}
                            validateEmail={(email) => isEmail(email)}
                            getLabel={(
                              email: string,
                              index: number,
                              removeEmail: (index: number) => void
                            ) => (
                              <div data-tag key={index}>
                                {email}
                                <span
                                  data-tag-handle
                                  onClick={() => removeEmail(index)}
                                >
                                  ×
                                </span>
                              </div>
                            )}
                          />
                        </Form.Group>
                        <Form.Row className="Form-ButtonsContainer">
                          <Col>
                            <Link
                              to={{ pathname: linkPath, tempCard, tempSchema }}
                              className="btn btn-cancel"
                              role="button"
                            >
                              Cancel
                            </Link>
                          </Col>
                          <Col>
                            <Link
                              to={{ pathname: linkPath, tempCard, tempSchema }}
                              onClick={this.onAddNotificationGroup}
                              className="btn btn-confirm"
                              role="button"
                            >
                              Save
                            </Link>
                          </Col>
                        </Form.Row>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationGroupBuilder);
