import Immutable from 'immutable';
import {
  MARK_NOTIFICATION_AS_READ,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  FETCH_ALERT_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_COUNT
} from '../actions/notificationsActions';

const initialState = Immutable.fromJS({
  notificationsObjects: [],
  count: 0
});

const markAllAsRead = function (notificationsObjects) {
  return notificationsObjects.map((v) => v.set('unread', false));
};
const markAsRead = function (notificationsObjects, notificationId) {
  return notificationsObjects.map((notification) => {
    if (notification.get('id') === notificationId) {
      return notification.set('unread', false);
    }
    return notification;
  });
};
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALERT_NOTIFICATIONS:
      return state.set(
        'notificationsObjects',
        action.payload.get('alert_notifications')
      );
    case MARK_ALL_NOTIFICATIONS_AS_READ:
      return state.set(
        'notificationsObjects',
        markAllAsRead(state.get('notificationsObjects'))
      ).set('count', 0);
    case MARK_NOTIFICATION_AS_READ:
      return state.set(
        'notificationsObjects',
        markAsRead(state.get('notificationsObjects'), action.payload)
      );
    case GET_ALL_NOTIFICATIONS_COUNT:
      return state.set(
        'count',
        action.payload.alert_notifications.count
      );
    default:
      return state;
  }
};
