import Immutable from 'immutable';
import { FETCH_ALERTS, SET_ALERTS } from '../actions/alertsActions';
import {
  MARK_NOTIFICATION_AS_READ,
  FETCH_ALERT_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  GET_ALL_NOTIFICATIONS_COUNT
} from '../actions/notificationsActions';
import {
  DELETE_METRIC,
  ADD_METRIC,
  FETCH_METRICS,
  SET_SHARED_METRICS,
  FETCH_METRIC_DATA
} from '../actions/metricsActions';
import {
  FETCH_NOTIFICATION_GROUPS,
  ADD_NOTIFICATION_GROUP,
  DELETE_NOTIFICATION_GROUP
} from '../actions/notificationGroupsActions';
import {
  FETCH_REPORTS,
  FETCH_WIDGET
} from '../actions/catalogsActions';
import {
  parseAlertsData,
  parseMetricsData,
  parseNotificationGroupsData,
  parseNotificationGroupAddData,
  parseMetricsAddData,
  parseMetricsDataData,
  parseAlertNotificationsData
} from './dataParsers';
import { FETCH_PROFILE } from '../actions/profileActions';
import {
  SHOW_SYSTEM_NOTIFICATION,
  TOGGLE_ADD_ALERT_STATUS
} from '../actions/viewActions';

function getApiToken(store) {
  const userProfile = store.getState().userProfileReducer;
  return userProfile.getIn(['profile', 'tokens', 'api']);
}

function getCsrfToken(store) {
  const userProfile = store.getState().userProfileReducer;
  return userProfile.getIn(['profile', 'tokens', 'csrf']);
}

function getRequestHeader(store) {
  return {
    'Content-Type': 'application/json',
    'X-Authentication-Token': getApiToken(store),
    'X-CSRF-Token': getCsrfToken(store)
  };
}

function getLegacyApiUrl(store) {
  const userProfile = store.getState().userProfileReducer;
  return userProfile.getIn(['profile', 'tokens', 'legacyApiUrl']);
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function hasTokens(store) {
  console.log('Redux Middleware: hasTokens');
  return !getLegacyApiUrl(store) || !getCsrfToken(store) || !getApiToken(store);
}

function fetchMetricData(store, next, action) {
  console.log('Redux Middleware: fetchMetricData', action);
  const metricId = action.payload;
  fetch(`${getLegacyApiUrl(store)}/alert_metrics/${metricId}/history`, {
    method: 'GET',
    headers: getRequestHeader(store)
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = Immutable.fromJS({
        metricId,
        data: parseMetricsDataData(result)
      });
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      if (hasTokens(store)) {
        document.location.href = '/Message';
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: "Unable to load Metric's Data"
        });
      }
    });
}

function fetchProfile(store, next, action) {
  console.log('Redux Middleware: fetchProfile');
  fetch(`${getLegacyApiUrl(store)}/users`, {
    method: 'GET',
    headers: getRequestHeader(store)
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = Immutable.fromJS(result);
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      if (hasTokens(store)) {
        document.location.href = '/Message';
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Unable to load Profile'
        });
      }
    });
}

function fetchReports(store, next, action) {
  console.log('Redux Middleware: fetchReports');
  fetch(
    `${getLegacyApiUrl(
      store
    )}/reports?exclude_source_report_views=true&include_notification_group_reports=true&non_source_and_shared_report_views=true`,
    { method: 'GET', headers: getRequestHeader(store) }
  )
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = Immutable.fromJS(result);
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      if (hasTokens(store)) {
        document.location.href = '/Message';
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Unable to load Reports Catalog'
        });
      }
    });
}

function fetchWidget(store, next, action) {
  const widgetId = action.payload;
  console.log('Redux Middleware: fetchWidget');
  fetch(
    `${getLegacyApiUrl(
      store
    )}/widgets/${widgetId}`,
    { method: 'GET', headers: getRequestHeader(store) }
  )
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = Immutable.fromJS(result);
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      if (hasTokens(store)) {
        document.location.href = '/Message';
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Unable to load Widget Catalog'
        });
      }
    });
}

function updateAlertNotification(store, next, action) {
  console.log('Redux Middleware: updateAlertNotification', action.payload);
  const alert_notification = action.payload;
  // call backend
  fetch(`${getLegacyApiUrl(store)}/alert_notifications/${alert_notification}`, {
    method: 'PUT',
    headers: getRequestHeader(store),
    body: JSON.stringify({
      alert_notification: {
        unread: false
      }
    })
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = alert_notification;
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      // call the Error function
      next({
        type: SHOW_SYSTEM_NOTIFICATION,
        payload: 'Unable to update Alert Notification'
      });
    });
}

function updateAllAlertNotification(store, next, action) {
  console.log('Redux Middleware: updateAllAlertNotification');
  // call backend
  fetch(`${getLegacyApiUrl(store)}/alert_notifications/mark_all_as_read`, {
    method: 'PUT',
    headers: getRequestHeader(store)
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      next(action);
    })
    .catch((error) => {
      console.log('Error', error);
      // call the Error function
      next({
        type: SHOW_SYSTEM_NOTIFICATION,
        payload: 'Unable to update Alert Notifications'
      });
    });
}

function getAllNotificationsCount(store, next, action) {
  console.log('Redux Middleware: getAllNotificationsCount');
  fetch(`${getLegacyApiUrl(store)}/alert_notifications/count`, {
    method: 'GET',
    headers: getRequestHeader(store)
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      const newAction = action;
      newAction.payload = result;
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      if (hasTokens(store)) {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Your session is not valid'
        });
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Unable to load Alert Notifications count'
        });
      }
    });
}

function fetchAlertNotifications(store, next, action) {
  console.log('Redux Middleware: fetchAlertNotifications');
  fetch(`${getLegacyApiUrl(store)}/alert_notifications`, {
    method: 'GET',
    headers: getRequestHeader(store)
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      store.dispatch({
        type: SET_ALERTS,
        payload: parseAlertsData(result)
      });
      store.dispatch({
        type: SET_SHARED_METRICS,
        payload: parseMetricsData(result)
      });
      store.dispatch({
        type: FETCH_REPORTS,
        payload: {}
      });
      const newAction = action;
      newAction.payload = parseAlertNotificationsData(result);
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      if (hasTokens(store)) {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Your session is not valid'
        });
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Unable to load Alerts'
        });
      }
    });
}

function fetchAlerts(store, next, action) {
  console.log('Redux Middleware: fetchAlerts');
  fetch(`${getLegacyApiUrl(store)}/alert_notifications`, {
    method: 'GET',
    headers: getRequestHeader(store)
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = parseAlertsData(result);
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      if (hasTokens(store)) {
        document.location.href = '/Message';
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Unable to load Alerts'
        });
      }
    });
}

function deleteMetric(store, next, action) {
  console.log('Redux Middleware: deleteMetric', action);
  // call backend
  const metricId = action.payload;
  fetch(`${getLegacyApiUrl(store)}/alert_metrics/${metricId}`, {
    method: 'DELETE',
    headers: getRequestHeader(store)
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = metricId;
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      // call the Error function
      if (hasTokens(store)) {
        document.location.href = '/Message';
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Unable to delete Alert'
        });
      }
    });
}

function getColumnData(store, columnId, metric) {
  const columnsCatalog = metric.get('widget') && metric.get('widget').has('id')
    ? store.getState().catalogsReducer.getIn(['widget', 'columns'])
    : store.getState().catalogsReducer.get('columns');
  const selectedColumn = columnsCatalog.find(
    (column) => column.get('id') == columnId
  );
  return [selectedColumn.get('column_id'), selectedColumn.get('id')];
}

function buildFilter(store, payload) {
  const metric = payload;
  const dimensions = payload.get('selectedColumns');
  const dateRange = payload.get('date_range');
  let columnData = getColumnData(store, metric.get('metric'), metric);
  const metricOperator = metric.get('metricOperator');
  let metricThreshold = metric.get('metricThreshold');
  if (metricOperator === 'Between' || metricOperator === 'NotBetween') {
    metricThreshold = metricThreshold.sort();
  } else {
    metricThreshold = [metricThreshold.first()];
  }
  const filter = [{
    column: columnData[0],
    id: columnData[1],
    logical_operator: 'and',
    columnType: 'metric',
    operator: metricOperator,
    type: 'inclusion',
    values: metricThreshold
  }]

  if (dateRange) {
    const dateFilter = {
      column: 'date',
      columnType: 'Date',
      logicalOperator: 'and',
      operator: 'TimeRange',
      type: 'inclusion',
      values: [dateRange]
    }
    filter.push(dateFilter);
  }

  dimensions.forEach((dimension) => {
    columnData = getColumnData(store, dimension.get('dimension'), metric);
    const dimensionFilter = {
      column: columnData[0],
      id: columnData[1],
      columnType: 'dimension',
      logical_operator: 'and',
      operator: dimension.get('dimensionOperator'),
      type: 'inclusion',
      values: dimension.get('dimensionThreshold')
    }
    filter.push(dimensionFilter);
  })
  return filter;
}

function fetchMetrics(store, next, action) {
  console.log('Redux Middleware: fetchMetrics');
  // call backend
  fetch(`${getLegacyApiUrl(store)}/alert_metrics`, {
    method: 'GET',
    headers: getRequestHeader(store)
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = parseMetricsData(result);
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      // call the Error function
      if (hasTokens(store)) {
        document.location.href = '/Message';
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Unable to load Metrics'
        });
      }
    });
}

function newMetric(store, next, action) {
  console.log('Redux Middleware: newMetric', action.payload);
  const metric = action.payload;
  const filter = buildFilter(store, metric);
  const reportColumnIds = filter.map((column) => column.id);
  // call backend
  fetch(`${getLegacyApiUrl(store)}/alert_metrics`,
    { method: 'POST',
      headers: getRequestHeader(store),
      body: JSON.stringify({
        alert_metric: {
          report_column_ids: reportColumnIds,
          report_view_id: metric.get('reportId'),
          chart_type: 'line',
          alert_frequency: metric.get('alert_frequency'),
          description: metric.get('description'),
          title: metric.get('title'),
          enabled: metric.get('enabled'),
          notification_group_ids: metric.get('notification_group_ids'),
          filter,
          alert_type: metric.get('alert_type'),
          widget_id: metric.getIn(['widget', 'id'])
        }
      })
    })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = parseMetricsAddData(result);
      next(newAction);
    })
    .then(() => {
      console.log('Redux Middleware: newMetric', TOGGLE_ADD_ALERT_STATUS);
      next({
        type: TOGGLE_ADD_ALERT_STATUS
      });
    })
    .catch((error) => {
      console.log('Error', error);
      // call the Error function
      next({
        type: SHOW_SYSTEM_NOTIFICATION,
        payload: 'Unable to add Metric'
      });
    });
}

function updateMetric(store, next, action) {
  console.log('Redux Middleware: updateMetric', action.payload);
  const metric = action.payload;
  const filter = buildFilter(store, metric);
  const reportColumnIds = filter.map((column) => column.id);
  // call backend
  fetch(`${getLegacyApiUrl(store)}/alert_metrics/${metric.get('id')}`,
    { method: 'PUT',
      headers: getRequestHeader(store),
      body: JSON.stringify({
        alert_metric: {
          report_column_ids: reportColumnIds,
          chart_type: 'line',
          alert_frequency: metric.get('alert_frequency'),
          description: metric.get('description'),
          title: metric.get('title'),
          enabled: metric.get('enabled'),
          notification_group_ids: metric.get('notification_group_ids'),
          filter,
          alert_type: metric.get('alert_type'),
          widget_id: metric.getIn(['widget', 'id'])
        }
      })
    })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = parseMetricsAddData(result);
      next(newAction);
    })
    .then(() => {
      console.log('Redux Middleware: updateMetric', TOGGLE_ADD_ALERT_STATUS);
      next({
        type: TOGGLE_ADD_ALERT_STATUS
      });
    })
    .catch((error) => {
      console.log('Error', error);
      // call the Error function
      next({
        type: SHOW_SYSTEM_NOTIFICATION,
        payload: 'Unable to update Metric'
      });
    });
}

function addMetric(store, next, action) {
  console.log('Redux Middleware: addMetric');
  if (action.payload.get('id') === null) {
    newMetric(store, next, action);
  } else {
    updateMetric(store, next, action);
  }
}

function fetchNotificationGroups(store, next, action) {
  console.log('Redux Middleware: fetchNotificationGroups');
  // call backend
  fetch(`${getLegacyApiUrl(store)}/notification_groups`, {
    method: 'GET',
    headers: getRequestHeader(store)
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = parseNotificationGroupsData(result);
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      // call the Error function
      if (hasTokens(store)) {
        document.location.href = '/Message';
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Unable to load Notification Groups'
        });
      }
    });
}

function updateNotificationGroup(store, next, action) {
  console.log('Redux Middleware: updateNotificationGroup', store, next, action);
  // call backend
  const notificationGroupsId = action.payload.get('id');
  fetch(
    `${getLegacyApiUrl(store)}/notification_groups/${notificationGroupsId}`,
    {
      method: 'PUT',
      headers: getRequestHeader(store),
      body: JSON.stringify({
        notification_group: {
          title: action.payload.get('groupName'),
          email_addresses: action.payload.get('emailAddresses')
        }
      })
    }
  )
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = parseNotificationGroupAddData(result);
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      // call the Error function
      if (hasTokens(store)) {
        document.location.href = '/Message';
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Unable to update Notification Group'
        });
      }
    });
}

function addNotificationGroup(store, next, action) {
  console.log('Redux Middleware: addNotificationGroup', store, next, action);
  // call backend
  fetch(`${getLegacyApiUrl(store)}/notification_groups`, {
    method: 'POST',
    headers: getRequestHeader(store),
    body: JSON.stringify({
      notification_group: {
        title: action.payload.get('groupName'),
        email_addresses: action.payload.get('emailAddresses')
      }
    })
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = parseNotificationGroupAddData(result);
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      // call the Error function
      if (hasTokens(store)) {
        document.location.href = '/Message';
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Unable to add Notification Group'
        });
      }
    });
}

function saveNotificationGroup(store, next, action) {
  console.log('Redux Middleware: saveNotificationGroup', store, next, action);
  if (action.payload.get('id')) {
    updateNotificationGroup(store, next, action);
  } else {
    addNotificationGroup(store, next, action);
  }
}

function deleteNotificationGroup(store, next, action) {
  console.log('Redux Middleware: deleteNotificationGroup');
  // call backend
  const notificationGroupsId = action.payload;
  console.log('notificationGroupsId', notificationGroupsId);
  fetch(
    `${getLegacyApiUrl(store)}/notification_groups/${notificationGroupsId}`,
    { method: 'DELETE', headers: getRequestHeader(store) }
  )
    .then(handleErrors)
    .then((response) => response.json())
    .then((result) => {
      console.log('Success', result);
      // call the Success function
      const newAction = action;
      newAction.payload = notificationGroupsId;
      next(newAction);
    })
    .catch((error) => {
      console.log('Error', error);
      // call the Error function
      if (hasTokens(store)) {
        document.location.href = '/Message';
      } else {
        next({
          type: SHOW_SYSTEM_NOTIFICATION,
          payload: 'Unable to delete Notification Group'
        });
      }
    });
}

export const middleware = (store) => (next) => (action) => {
  console.log('Redux Middleware:', action);
  switch (action.type) {
    case FETCH_ALERTS:
      fetchAlerts(store, next, action);
      break;
    case FETCH_METRICS:
      fetchMetrics(store, next, action);
      break;
    case ADD_METRIC:
      addMetric(store, next, action);
      break;
    case DELETE_METRIC:
      deleteMetric(store, next, action);
      break;
    case FETCH_NOTIFICATION_GROUPS:
      fetchNotificationGroups(store, next, action);
      break;
    case ADD_NOTIFICATION_GROUP:
      saveNotificationGroup(store, next, action);
      break;
    case DELETE_NOTIFICATION_GROUP:
      deleteNotificationGroup(store, next, action);
      break;
    case FETCH_REPORTS:
      fetchReports(store, next, action);
      break;
    case FETCH_WIDGET:
      fetchWidget(store, next, action);
      break;
    case FETCH_PROFILE:
      fetchProfile(store, next, action);
      break;
    case FETCH_METRIC_DATA:
      fetchMetricData(store, next, action);
      break;
    case MARK_NOTIFICATION_AS_READ:
      updateAlertNotification(store, next, action);
      break;
    case MARK_ALL_NOTIFICATIONS_AS_READ:
      updateAllAlertNotification(store, next, action);
      break;
    case GET_ALL_NOTIFICATIONS_COUNT:
      getAllNotificationsCount(store, next, action);
      break;
    case FETCH_ALERT_NOTIFICATIONS:
      fetchAlertNotifications(store, next, action);
      break;
    default:
      next(action);
  }
};
