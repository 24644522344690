import Immutable from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../../components/Header/Header';
import Menu from '../../components/Menu/Menu';
import { addMetric } from '../../redux/actions/metricsActions';
import AlertBuilderComponent from '../../components/AlertBuilder/AlertBuilder';
import { fetchReports, fetchWidget } from '../../redux/actions/catalogsActions';
import { fetchNotificationGroups } from '../../redux/actions/notificationGroupsActions';
import { togglePageStatus } from '../../redux/actions/viewActions';
import './AlertBuilder.css';

/*
 * mapDispatchToProps
 */
const mapDispatchToProps = (dispatch) => ({
  addMetric: (card) => dispatch(addMetric(card)),
  fetchReports: () => dispatch(fetchReports()),
  fetchWidget: (id) => dispatch(fetchWidget(id)),
  fetchNotificationGroups: () => dispatch(fetchNotificationGroups()),
  togglePageStatus: (id) => dispatch(togglePageStatus(id))
});

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  ...state
});

/**
 * @class AlertBuilder
 * @extends {Component}
 */
class AlertBuilder extends Component {
  static propTypes = {
    fetchReports: PropTypes.func.isRequired,
    fetchWidget: PropTypes.func.isRequired,
    fetchNotificationGroups: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    addMetric: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    // eslint-disable-next-line
    history: PropTypes.object,
    viewReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    notificationGroupsReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    userProfileReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    catalogsReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    togglePageStatus: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      fetchReports,
      viewReducer,
      fetchNotificationGroups,
      notificationGroupsReducer,
      togglePageStatus,
      fetchWidget
    } = this.props;
    const widgetId = this.getWidgetId();
    const pageName = widgetId ? 'alertBuilderTotal' : 'alertBuilder';
    if (!viewReducer.getIn(['view', 'pages', pageName, 'firstLoad'])) {
      if (widgetId) {
        fetchWidget(widgetId);
      } else {
        fetchReports();
      }
      if (notificationGroupsReducer.get('notification_groups').isEmpty()) {
        fetchNotificationGroups();
      }
      togglePageStatus(pageName);
    }
  }

  getWidgetId = () => {
    const widgetId = this.props.match.params.widgetId
      ? decodeURIComponent(this.props.match.params.widgetId)
      : false;
    if (widgetId) {
      return widgetId;
    }
    const isEdition = this.props.location.params && this.props.location.params.metric;
    if (isEdition) {
      const card = this.props.location.params.metric;
      if (card.get('widget')) {
        return card.get('widget').has('id')
          ? card.get('widget').get('id')
          : false;
      }
    }
    return false;
  };

  render() {
    const { viewReducer, notificationGroupsReducer } = this.props;
    const widgetId = this.getWidgetId();
    const pageName = widgetId ? 'alertBuilderTotal' : 'alertBuilder';
    const isResourcesReady = viewReducer.getIn([
      'view',
      'pages',
      pageName,
      'isResourcesReady'
    ]);
    const hasAlertAdded = viewReducer.getIn([
      'view',
      'pages',
      pageName,
      'hasAlertAdded'
    ]);
    const loadingAlertBuildersMessage = !isResourcesReady ? (
      <Row className="Page-emptyAlertsMessage">
        <Col className="Page-loadingMessageContainer">
          <div className="Page-loadingMessage">Loading...</div>
        </Col>
      </Row>
    ) : null;
    const FLUID = true;
    return (
      <Container fluid={FLUID} className="Page-Layout">
        <Row>
          <Header profile={this.props.userProfileReducer.get('profile')} />
        </Row>
        <Menu />
        <Row className="Page-Main">
          <Col>
            <Container>
              {loadingAlertBuildersMessage}
              <Row>
                <Col align="center">
                  {isResourcesReady && (
                    <AlertBuilderComponent
                      location={this.props.location}
                      addMetric={this.props.addMetric}
                      match={this.props.match}
                      history={this.props.history}
                      catalogs={this.props.catalogsReducer}
                      notificationGroups={notificationGroupsReducer.get(
                        'notification_groups'
                      )}
                      hasAlertAdded={hasAlertAdded}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertBuilder);
