import React from 'react';
import './Message.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import logo from '../../static/logo.png';

const FLUID = true;
/**
 * @class Message
 * @extends {Component}
 */
class Message extends React.PureComponent {
  render() {
    return (
      <Container fluid={FLUID} className="Page-Layout">
        <Row className="Page-Main">
          <Col align="center">
            <Card className="Message-Container">
              <Card.Body>
                <div className="Message-container">
                  <img
                    src={logo}
                    className="d-inline-block align-top Message-logo"
                    alt="logo"
                  />
                </div>
                <Alert variant="danger" className="Message-alert">
                  <i className="fa fa-exclamation-triangle Message-icon"></i>
                  There was an error logging into STAQ Intelligence
                </Alert>
                <h6 className="Message-threshold">Please try again.</h6>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Message;
