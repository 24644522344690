export const FETCH_NOTIFICATION_GROUPS = 'FETCH_NOTIFICATION_GROUPS';
export const DELETE_NOTIFICATION_GROUP = 'DELETE_NOTIFICATION_GROUP';
export const ADD_NOTIFICATION_GROUP = 'ADD_NOTIFICATION_GROUP';

export const fetchNotificationGroups = (notificationGroups) => (dispatch) => {
  dispatch({
    type: FETCH_NOTIFICATION_GROUPS,
    payload: notificationGroups
  });
};
export const deleteNotificationGroup = (id) => (dispatch) => {
  dispatch({
    type: DELETE_NOTIFICATION_GROUP,
    payload: id
  });
};
export const addNotificationGroup = (notificationGroup) => (dispatch) => {
  dispatch({
    type: ADD_NOTIFICATION_GROUP,
    payload: notificationGroup
  });
};
