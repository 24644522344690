import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkContainer } from 'react-router-bootstrap';
import Moment from 'react-moment';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import numeral from 'numeraljs';
import './AlertNotificationCard.scss';
import { OPERATOR_LABELS,
  INLINE_ALERT_TYPE,
  TOTAL_ALERT_TYPE,
  SEPARATOR
} from '../Widgets/utils/catalogs';

/**
 * @class AlertNotificationCard
 * @extends {Component}
 */
class AlertNotificationCard extends Component {
  static propTypes = {
    alert: PropTypes.instanceOf(Immutable.Record).isRequired,
    metric: PropTypes.instanceOf(Immutable.Record).isRequired,
    alertNotification: PropTypes.instanceOf(Immutable.Record).isRequired,
    hideDetailsButton: PropTypes.bool,
    markAsRead: PropTypes.func.isRequired,
    staqAppUrl: PropTypes.string.isRequired
  };

  static defaultProps = {
    hideDetailsButton: false
  };

  onMarkNotificationAsRead = (alertNotificationId) => {
    this.props.markAsRead(alertNotificationId);
  };

  getDetailsLink = () => {
    const { metric, hideDetailsButton, staqAppUrl } = this.props;
    const detailsButtonClass = hideDetailsButton ? 'none' : 'block';
    const alertType = metric.get('alert_type');
    if (alertType === INLINE_ALERT_TYPE) {
      const filters = metric.get('filter');
      const filtersForURI = escape(encodeURIComponent(JSON.stringify(filters)));
      return (
        <a
          className="AlertNotificationCard-DetailsButton"
          style={{ display: detailsButtonClass }}
          href={`${staqAppUrl}/report_viewer/report_views/${metric.get(
            'reportId'
          )}?filters=${filtersForURI}`}
        >
          <Button className="btn btn-primary">Details</Button>
        </a>
      );
    }
    return (
      <LinkContainer
        className="AlertNotificationCard-DetailsButton"
        style={{ display: detailsButtonClass }}
        to={{
          pathname: `/Alerts/${metric.get('id')}`
        }}
      >
        <Button className="btn btn-primary">Details</Button>
      </LinkContainer>
    );
  };

  renderNotificationMessage = () => {
    const { alert, metric } = this.props;
    const prefix = metric.get('metricType') === 'Currency' ? '$' : '';
    const suffix = metric.get('metricType') === 'Percentage' ? '%' : '';
    const rowCount = alert.get('rowCount') < 1000 ? alert.get('rowCount') : '1000+';
    const alertType = metric.get('alert_type');
    if (alertType === TOTAL_ALERT_TYPE) {
      const metricFilter = alert.getIn(['alertMetric']).get('filter');
      const metricOperator = metricFilter.first().get('operator');
      const isTwoValuesCondition = metricOperator === 'Between' || metricOperator === 'NotBetween';
      const metricThreshold = alert.getIn(['alertMetric', 'metricThreshold']);
      const metricLabel = metric.get('metricLabel')
        ? metric.get('metricLabel')
        : metricFilter.first().get('column');
      const conditionMessage = isTwoValuesCondition
        ? `${metricLabel} ${OPERATOR_LABELS[metricOperator]} ${metricThreshold.get(0)} and ${metricThreshold.get(1)}`
        : `${metricLabel} ${OPERATOR_LABELS[metricOperator]} ${metricThreshold.get(0)}`;
      return (
        <div>
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              <i className="fa fa-circle AlertNotificationCard-condition"></i>
              <div className="AlertNotificationCard-Condition">
                <div>{conditionMessage}</div>
              </div>
              <br />
              <br />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="AlertNotificationCard-title">
                {prefix}
                {numeral(alert.value).format('0,0.[00]')}
                {suffix}
                {SEPARATOR}
                {metric.get('metricLabel')}
              </div>
            </Col>
          </Row>
        </div>
      )
    }
    if (rowCount) {
      return (
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="AlertNotificationCard-title">
              {rowCount}
              {SEPARATOR}
              {rowCount === 1 ? 'row ' : 'rows '}
              met the alert criteria
            </div>
          </Col>
        </Row>
      );
    }
    return null;
  }

  render() {
    const { alert, alertNotification } = this.props;
    return (
      <Card className="AlertNotificationCard-main">
        <Card.Header as="h5" className="AlertNotificationCard-header">
          <ButtonToolbar>
            <i className="fa fa-bell"></i>
            <div className="AlertNotificationCard-title-container">
              <div className="AlertNotificationCard-title">{alert.title}</div>
            </div>
          </ButtonToolbar>
          <div className="AlertNotificationCard-tooltip">
            <OverlayTrigger
              placement="right"
              overlay={(
                <Tooltip id="tooltip-auto">
                  <Moment format="LLL">{moment.utc(alert.triggered, 'YYYY-MM-DD HH:mm:ss TZD').toDate()}</Moment>
                </Tooltip>
              )}
            >
              <div className="AlertNotificationCard-timestamp">
                <Moment fromNow>{moment.utc(alert.triggered, 'YYYY-MM-DD HH:mm:ss TZD').toDate()}</Moment>
              </div>
            </OverlayTrigger>
          </div>
        </Card.Header>
        <Card.Body>
          {this.renderNotificationMessage()}
          <Row>
            <Col className="AlertNotificationCard-ButtonToolbarContainer">
              <ButtonToolbar className="AlertNotificationCard-ButtonToolbar">
                {this.getDetailsLink()}
                <Button
                  size="sm"
                  className="btn btn-secondary"
                  onClick={this.onMarkNotificationAsRead.bind(
                    null,
                    alertNotification.get('id')
                  )}
                >
                  Mark as Read
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

export default AlertNotificationCard;
