import firebase from 'firebase/app';

export default function initialize() {
  const config = {
    apiKey: 'AIzaSyA77EUvmizxyHVPtFYh7Vxxb6d4hFfJBvY',
    authDomain: 'staq-com-staq-api-access.firebaseapp.com',
    databaseURL: 'https://staq-com-staq-api-access.firebaseio.com',
    projectId: 'staq.com:staq-api-access',
    storageBucket: '',
    messagingSenderId: '1076170303045'
  };

  firebase.initializeApp(config);
}
