export const SHOW_SYSTEM_NOTIFICATION = 'SHOW_SYSTEM_NOTIFICATION';
export const TOGGLE_PAGE_STATUS = 'TOGGLE_PAGE_STATUS';
export const TOGGLE_RESOURCE_STATUS = 'TOGGLE_RESOURCE_STATUS';
export const TOGGLE_ADD_ALERT_STATUS = 'TOGGLE_ADD_ALERT_STATUS';
export const CLEAR_WIDGET_CATALOG = 'CLEAR_WIDGET_CATALOG';

export const showSystemNotification = (message) => (dispatch) => {
  dispatch({
    type: SHOW_SYSTEM_NOTIFICATION,
    payload: message
  });
};

export const togglePageStatus = (pageName) => (dispatch) => {
  dispatch({
    type: TOGGLE_PAGE_STATUS,
    payload: pageName
  });
};

export const toggleResourceStatus = (pageName, resourceName) => (dispatch) => {
  dispatch({
    type: TOGGLE_RESOURCE_STATUS,
    payload: {
      pageName,
      resourceName
    }
  });
};

export const toggleAddAlertStatus = () => (dispatch) => {
  dispatch({
    type: TOGGLE_ADD_ALERT_STATUS
  });
};

export const clearWidgetCatalog = () => (dispatch) => {
  dispatch({
    type: CLEAR_WIDGET_CATALOG
  });
};
