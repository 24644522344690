import Immutable from 'immutable';
import {
  DELETE_METRIC,
  ADD_METRIC,
  FETCH_METRICS,
  FETCH_METRIC_DATA,
  SET_TEMP_FORM_STATUS
} from '../actions/metricsActions';

const initialState = Immutable.fromJS({ cards: [], tempStatus: false });

function deleteMetric(selectedMetricId, metrics) {
  console.log('deleteMetric', selectedMetricId, metrics);
  return metrics.filter((metric) => metric.get('id') !== selectedMetricId);
}

function setMetricData(selectedMetricId, metrics, data) {
  return metrics.map((metric) => {
    if (metric.get('id') !== selectedMetricId) {
      return metric.set('data', data);
    }
    return metric;
  });
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_METRICS:
      return state.set('cards', action.payload.get('cards'));
    case DELETE_METRIC:
      return state.set(
        'cards',
        deleteMetric(action.payload, state.get('cards'))
      );
    case ADD_METRIC:
      return state.set(
        'cards',
        deleteMetric(action.payload.get('id'), state.get('cards')).insert(
          0,
          action.payload
        )
      );
    case FETCH_METRIC_DATA:
      return state.set(
        'cards',
        setMetricData(
          action.payload.get('id'),
          state.get('cards'),
          action.payload.get('data')
        )
      );
    case SET_TEMP_FORM_STATUS:
      return state.set('tempStatus', action.payload);
    default:
      return state;
  }
};
