import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Badge from 'react-bootstrap/Badge';
import { LinkContainer } from 'react-router-bootstrap';
import { INLINE_ALERT_TYPE } from '../../Widgets/utils/catalogs';
import './AlertChip.scss';
/**
 * @class AlertChip
 * @extends {Component}
 */
class AlertChip extends Component {
  static propTypes = {
    alerts: PropTypes.instanceOf(Immutable.List).isRequired,
    metric: PropTypes.instanceOf(Immutable.Record).isRequired,
    staqAppUrl: PropTypes.string.isRequired
  };

  renderAlertMetricBadge(metric) {
    const { alerts, staqAppUrl } = this.props;
    const metricAlerts = alerts.filter(
      (alert) => alert.get('metricId') === metric.get('id')
    );
    const alertType = metric.get('alert_type');
    if (alertType === INLINE_ALERT_TYPE) {
      const filters = metric.get('filter');
      const filtersForURI = escape(encodeURIComponent(JSON.stringify(filters)));
      return !metricAlerts.isEmpty()
        && metric.get('enabled')
        && metric.metricAlertCount > 0 ? (
          <div className="AlertChip-Badge">
            <a
              className="AlertsTable-ReportLink"
              href={`${staqAppUrl}/report_viewer/report_views/${metric.get(
                'reportId'
              )}?filters=${filtersForURI}`}
            >
              <Badge className="btn-primary">{metric.metricAlertCount}</Badge>
            </a>
          </div>
        ) : (
          <div className="AlertChip-Badge" />
        );
    }
    return !metricAlerts.isEmpty()
      && metric.get('enabled')
      && metric.metricAlertCount > 0 ? (
        <div className="AlertChip-Badge">
          <LinkContainer
            to={{
              pathname: `/Alerts/${metric.get('id')}`
            }}
          >
            <Badge className="btn-primary">{metric.metricAlertCount}</Badge>
          </LinkContainer>
        </div>
      ) : (
        <div className="AlertChip-Badge" />
      );
  }

  render() {
    const { metric } = this.props;
    if (metric.get('broken')) {
      return (
        <div className="BrokenAlertChip-MetricRow">
          <div>{metric.get('title')}</div>
          <div className="AlertChip-Panel">
            <div className="AlertChip-EditAction">
              <LinkContainer
                to={{
                  pathname: '/AlertBuilder',
                  params: { metric }
                }}
              >
                <i className="fa fa-pencil"></i>
              </LinkContainer>
            </div>
            {this.renderAlertMetricBadge(metric)}
          </div>
        </div>
      )
    }
    return (
      <div className="AlertChip-MetricRow">
        <div>{metric.get('title')}</div>
        <div className="AlertChip-Panel">
          <div className="AlertChip-EditAction">
            <LinkContainer
              to={{
                pathname: '/AlertBuilder',
                params: { metric }
              }}
            >
              <i className="fa fa-pencil"></i>
            </LinkContainer>
          </div>
          {this.renderAlertMetricBadge(metric)}
        </div>
      </div>
    );
  }
}
export default AlertChip;
