import Immutable from 'immutable';

export const schema = Immutable.fromJS({
  form: {
    validated: false,
    isValid: false,
    fields: {
      title: {
        required: true,
        isInvalid: true
      },
      description: {
        required: false,
        isInvalid: false
      },
      reportId: {
        required: true,
        isInvalid: true
      },
      dimensions: Immutable.List(),
      metric: {
        required: true,
        isInvalid: true
      },
      metricOperator: {
        required: true,
        isInvalid: true
      },
      metricThreshold: {
        required: true,
        isInvalid: true
      },
      metricThresholdDown: {
        required: false,
        isInvalid: false
      },
      date_range: {
        required: false,
        isInvalid: false
      },
      alert_frequency: {
        required: true,
        isInvalid: true
      },
      alert_frequencyUnit: {
        required: false,
        isInvalid: false
      },
      alert_frequencyStartTime: {
        required: false,
        isInvalid: false
      },
      notification_group_ids: {
        required: false,
        isInvalid: false
      },
      enabled: {
        required: false,
        isInvalid: false
      }
    }
  }
});

export const dimension = Immutable.fromJS({
  dimension: {
    required: true,
    isInvalid: true
  },
  dimensionOperator: {
    required: true,
    isInvalid: true
  },
  dimensionThreshold: {
    required: true,
    isInvalid: true
  }
});
