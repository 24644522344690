export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const MARK_ALL_NOTIFICATIONS_AS_READ = 'MARK_ALL_NOTIFICATIONS_AS_READ';
export const GET_ALL_NOTIFICATIONS_COUNT = 'GET_ALL_NOTIFICATIONS_COUNT';
export const FETCH_ALERT_NOTIFICATIONS = 'FETCH_ALERT_NOTIFICATIONS';

/*
  src/redux/actions/notificationsActions.js
*/
export const markNotificationAsRead = (id) => (dispatch) => {
  dispatch({
    type: MARK_NOTIFICATION_AS_READ,
    payload: id
  });
};

export const markAllNotificationsAsRead = () => (dispatch) => {
  dispatch({
    type: MARK_ALL_NOTIFICATIONS_AS_READ
  });
};

export const getAllNotificationsCount = () => (dispatch) => {
  dispatch({
    type: GET_ALL_NOTIFICATIONS_COUNT
  });
};

export const fetchAlertNotifications = (alert_notifications) => (dispatch) => {
  dispatch({
    type: FETCH_ALERT_NOTIFICATIONS,
    payload: alert_notifications
  });
};
