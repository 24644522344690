import Immutable from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './NotificationGroup.scss';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Overlay from 'react-bootstrap/Overlay';
import Header from '../../components/Header/Header';
import Menu from '../../components/Menu/Menu';
import ConfirmationPopover from '../../components/ConfirmationPopover/ConfirmationPopover';
import { deleteNotificationGroup,
  fetchNotificationGroups } from '../../redux/actions/notificationGroupsActions';
import { togglePageStatus } from '../../redux/actions/viewActions';

/*
 * mapDispatchToProps
 */
const mapDispatchToProps = (dispatch) => ({
  fetchNotificationGroups: () => dispatch(fetchNotificationGroups()),
  deleteNotificationGroup: (id) => dispatch(deleteNotificationGroup(id)),
  togglePageStatus: (id) => dispatch(togglePageStatus(id))
});

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  ...state
});

/**
 * @class NotificationGroup
 * @extends {Component}
 */
class NotificationGroup extends Component {
  static propTypes = {
    deleteNotificationGroup: PropTypes.func.isRequired,
    fetchNotificationGroups: PropTypes.func.isRequired,
    togglePageStatus: PropTypes.func.isRequired,
    viewReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    notificationGroupsReducer: PropTypes.instanceOf(Immutable.Map).isRequired,
    userProfileReducer: PropTypes.instanceOf(Immutable.Map).isRequired
  };

  constructor(...args) {
    super(...args);

    this.state = {
      show: false,
      selectedItem: null
    };
  }

  componentDidMount() {
    const {
      fetchNotificationGroups,
      togglePageStatus,
      viewReducer
    } = this.props;
    if (
      !viewReducer.getIn(['view', 'pages', 'notificationGroups', 'firstLoad'])
    ) {
      fetchNotificationGroups();
      togglePageStatus('notificationGroups');
    }
  }

  onDeleteNotificationGroup = (notificationGroup) => (e) => {
    this.setState({ target: e.target });
    this.setState({ show: !this.state.show, selectedItem: notificationGroup });
  };

  onDeleteNotificationGroupAction = () => {
    this.setState({ show: false });
    this.props.deleteNotificationGroup(this.state.selectedItem.get('id'));
  };

  maybePluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

  render() {
    const { show, target } = this.state;
    const popover = (
      <ConfirmationPopover
        id="tooltip-trigger-focus"
        body="Deleting this notification group is irreversible and recipients will no longer receive alerts."
        deleteitem={() => this.onDeleteNotificationGroupAction()}
        cancel={() => this.setState({ show: false })}
      />
    );
    const notification_groups = this.props.notificationGroupsReducer.get(
      'notification_groups'
    );
    const { viewReducer } = this.props;
    let emptyNotificationGroupsMessage = !viewReducer.getIn([
      'view',
      'pages',
      'notificationGroups',
      'isResourcesReady'
    ]) ? (
      <Row className="Page-emptyAlertsMessage">
        <Col className="Page-loadingMessageContainer">
          <div className="Page-loadingMessage">Loading...</div>
        </Col>
      </Row>
      ) : null;
    emptyNotificationGroupsMessage = viewReducer.getIn(['view', 'pages', 'notificationGroups', 'firstLoad'])
    && viewReducer.getIn([
      'view',
      'pages',
      'notificationGroups',
      'isResourcesReady'
    ])
    && notification_groups.isEmpty() ? (
      <Row className="Page-emptyAlertsMessage">
        <Col>
          <Jumbotron>
            <h2>
              <i className="fa fa-users Page-IconLarge"></i>
            </h2>
            <p>
              <Link
                to="/NotificationGroupBuilder"
                className="btn btn-secondary"
              >
                + Add Notification Group
              </Link>
            </p>
          </Jumbotron>
        </Col>
      </Row>
      ) : (
        emptyNotificationGroupsMessage
      );
    const FLUID = true;
    return (
      <Container fluid={FLUID} className="Page-Layout">
        <Row>
          <Header profile={this.props.userProfileReducer.get('profile')} />
        </Row>
        <Menu notification_groups={notification_groups} />
        <Row className="Page-Main">
          <Col>
            <Container>
              {emptyNotificationGroupsMessage}
              <Row>
                <Col align="center">
                  {!notification_groups.isEmpty() && (
                    <Card className="NotificationGroup-Main">
                      <Card.Body>
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email Addresses</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <Overlay
                              target={target}
                              show={show}
                              placement="bottom-end"
                              id="popover-trigger-focus"
                            >
                              {({
                                placement,
                                scheduleUpdate,
                                arrowProps,
                                outOfBoundaries,
                                show: _show,
                                ...props
                              }) => <div {...props}>{popover}</div>}
                            </Overlay>
                            {notification_groups.map((notificationGroup, i) => (
                              <tr key={notificationGroup.get('id')}>
                                <td>{notificationGroup.get('groupName')}</td>
                                <td>
                                  {this.maybePluralize(
                                    notificationGroup.get('emailAddresses')
                                      .size,
                                    'email'
                                  )}
                                </td>
                                <td>
                                  <ButtonToolbar className="NotificationGroup-ButtonToolbar">
                                    <div className="NotificationGroup-ActionButton">
                                      <OverlayTrigger
                                        trigger="hover"
                                        placement="top"
                                        overlay={(
                                          <Tooltip
                                            id="tooltip-auto"
                                            style={{
                                              fontSize: 11,
                                              opacity: 0.8
                                            }}
                                          >
                                            Edit Group
                                          </Tooltip>
                                        )}
                                      >
                                        <Link
                                          to={{
                                            pathname:
                                              '/NotificationGroupBuilder',
                                            params: { notificationGroup }
                                          }}
                                          className="NotificationGroup-EditIcon"
                                        >
                                          <i className="fa fa-pencil"></i>
                                        </Link>
                                      </OverlayTrigger>
                                    </div>
                                    <div
                                      className="NotificationGroup-ActionButton"
                                      onClick={this.onDeleteNotificationGroup(
                                        notificationGroup
                                      )}
                                    >
                                      <OverlayTrigger
                                        trigger="hover"
                                        placement="top"
                                        overlay={(
                                          <Tooltip
                                            id="tooltip-auto"
                                            style={{
                                              fontSize: 11,
                                              opacity: 0.8
                                            }}
                                          >
                                            Delete Group
                                          </Tooltip>
                                        )}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </OverlayTrigger>
                                    </div>
                                  </ButtonToolbar>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationGroup);
