import Immutable from 'immutable';
// import { NotificationGroup } from '../records';
import {
  DELETE_NOTIFICATION_GROUP,
  ADD_NOTIFICATION_GROUP,
  FETCH_NOTIFICATION_GROUPS
} from '../actions/notificationGroupsActions';

const initialState = Immutable.fromJS({ notification_groups: [] });

function deleteNotificationGroup(
  selectedNotificationGroupId,
  notificationGroups
) {
  return notificationGroups.filter(
    (notificationGroup) => notificationGroup.get('id') !== selectedNotificationGroupId
  );
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_GROUPS:
      return state.set(
        'notification_groups',
        action.payload
          .get('notification_groups')
          .filter((i) => i.groupName !== 'In-App Only')
      );
    case DELETE_NOTIFICATION_GROUP:
      return state.set(
        'notification_groups',
        deleteNotificationGroup(
          action.payload,
          state.get('notification_groups')
        )
      );
    case ADD_NOTIFICATION_GROUP:
      return state.set(
        'notification_groups',
        deleteNotificationGroup(
          action.payload.get('id'),
          state.get('notification_groups')
        ).insert(0, action.payload)
      );
    default:
      return state;
  }
};
