import Immutable from 'immutable';
import { FETCH_REPORTS, FETCH_WIDGET } from '../actions/catalogsActions';
import { SET_SHARED_METRICS } from '../actions/metricsActions';

const initialState = Immutable.fromJS({
  reports: [],
  columns: [],
  widget: {},
  dashabords: {},
  sharedMetrics: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTS:
      return state
        .set('reports', action.payload.get('reports'))
        .set('columns', action.payload.get('columns'));
    case FETCH_WIDGET:
      return state
        .set('widget', action.payload.get('widget'))
        .setIn(['widget', 'columns'], action.payload.get('columns'));
    case SET_SHARED_METRICS:
      return state
        .set('sharedMetrics', action.payload.get('cards'));
    default:
      return state;
  }
};
