import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkContainer } from 'react-router-bootstrap';
import Moment from 'react-moment';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import numeral from 'numeraljs';
import './AlertCard.scss';
import { OPERATOR_LABELS, INLINE_ALERT_TYPE, SEPARATOR } from '../Widgets/utils/catalogs';

/**
 * @class AlertCard
 * @extends {Component}
 */
class AlertCard extends React.PureComponent {
  static propTypes = {
    profile: PropTypes.instanceOf(Immutable.Map).isRequired,
    alert: PropTypes.instanceOf(Immutable.Record).isRequired,
    metrics: PropTypes.instanceOf(Immutable.List).isRequired
  };

  UNSAFE_componentWillMount() {
    const { alert, metrics } = this.props;
    const staqAppUrl = this.props.profile.getIn(['tokens', 'staqAppUrl']);
    const metric = metrics.find((m) => m.get('id') === alert.get('metricId'));
    if (!metric) return null;
    const alertType = metric.get('alert_type');
    if (alertType === INLINE_ALERT_TYPE) {
      const filters = metric.get('filter');
      const filtersForURI = escape(encodeURIComponent(JSON.stringify(filters)));
      const redirectUrl = `${staqAppUrl}/report_viewer/report_views/${metric.get(
        'reportId'
      )}?filters=${filtersForURI}`;
      window.location.assign(redirectUrl);
    }
    return null;
  }

  render() {
    const { alert, metrics } = this.props;
    const metric = metrics.find((m) => m.get('id') === alert.get('metricId'));
    if (!metric) return null;
    const prefix = metric.get('metricType') === 'Currency' ? '$' : '';
    const suffix = metric.get('metricType') === 'Percentage' ? '%' : '';
    const metricOperator = alert.getIn(['alertMetric', 'metricOperator']);
    const isTwoValuesCondition = metricOperator === 'Between' || metricOperator === 'NotBetween';
    const metricThreshold = alert.getIn(['alertMetric', 'metricThreshold']);
    const conditionMessageLabel = isTwoValuesCondition
      ? `${metric.get('metricLabel')} ${OPERATOR_LABELS[metricOperator]} ${metricThreshold.get(0)} and ${metricThreshold.get(1)}`
      : `${metric.get('metricLabel')} ${OPERATOR_LABELS[metricOperator]} ${metricThreshold.get(0)}`;
    const conditionMessage = (
      <div className="AlertCard-Condition">
        <div>{conditionMessageLabel}</div>
      </div>
    );
    return (
      <Card className="AlertCard-main">
        <Card.Header as="h5" className="AlertCard-header">
          <ButtonToolbar>
            <i className="fa fa-bell"></i>
            <div className="AlertCard-title-container">
              <div className="AlertCard-title">{alert.title}</div>
            </div>
          </ButtonToolbar>
          <div className="AlertCard-tooltip">
            <OverlayTrigger
              placement="right"
              overlay={(
                <Tooltip id="tooltip-auto">
                  <Moment format="LLL">{moment.utc(alert.triggered, 'YYYY-MM-DD HH:mm:ss TZD').toDate()}</Moment>
                </Tooltip>
              )}
            >
              <div className="AlertCard-timestamp">
                <Moment fromNow>{moment.utc(alert.triggered, 'YYYY-MM-DD HH:mm:ss TZD').toDate()}</Moment>
              </div>
            </OverlayTrigger>
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              <i className="fa fa-circle AlertCard-condition"></i>
              {conditionMessage}
              <br />
              <br />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="AlertCard-title">
                {prefix}
                {numeral(alert.value).format('0,0.[00]')}
                {suffix}
                {SEPARATOR}
                {metric.get('metricLabel')}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="AlertCard-ButtonToolbarContainer">
              <ButtonToolbar className="AlertCard-ButtonToolbar">
                <LinkContainer
                  className="AlertCard-DetailsButton"
                  to={{
                    pathname: '/AlertBuilder',
                    params: { metric }
                  }}
                >
                  <Button className="btn btn-primary">Edit</Button>
                </LinkContainer>
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

export default AlertCard;
