import { combineReducers } from 'redux';
import userProfileReducer from './userProfileReducer';
import alertsReducer from './alertsReducer';
import catalogsReducer from './catalogsReducer';
import notificationsReducer from './notificationsReducer';
import notificationGroupsReducer from './notificationGroupsReducer';
import metricsReducer from './metricsReducer';
import viewReducer from './viewReducer';

export default combineReducers({
  userProfileReducer,
  alertsReducer,
  notificationsReducer,
  notificationGroupsReducer,
  metricsReducer,
  viewReducer,
  catalogsReducer
});
