import Immutable from 'immutable';
import {
  SHOW_SYSTEM_NOTIFICATION,
  TOGGLE_PAGE_STATUS,
  TOGGLE_RESOURCE_STATUS,
  TOGGLE_ADD_ALERT_STATUS,
  CLEAR_WIDGET_CATALOG
} from '../actions/viewActions';
import {
  FETCH_METRICS,
  FETCH_METRIC_DATA
} from '../actions/metricsActions';
import { FETCH_ALERTS } from '../actions/alertsActions';
import { FETCH_ALERT_NOTIFICATIONS } from '../actions/notificationsActions';
import { FETCH_NOTIFICATION_GROUPS } from '../actions/notificationGroupsActions';
import { FETCH_REPORTS, FETCH_WIDGET } from '../actions/catalogsActions';

const REQUIRED_RESOURCES_BY_PAGE = Immutable.fromJS({
  main: {
    resources: [FETCH_METRICS, FETCH_ALERT_NOTIFICATIONS, FETCH_REPORTS]
  },
  notificationGroups: {
    resources: [FETCH_NOTIFICATION_GROUPS]
  },
  alertManager: {
    resources: [FETCH_METRICS, FETCH_REPORTS]
  },
  alertBuilder: {
    resources: [FETCH_REPORTS, FETCH_NOTIFICATION_GROUPS]
  },
  alertBuilderTotal: {
    resources: [FETCH_WIDGET, FETCH_NOTIFICATION_GROUPS]
  },
  alerts: {
    resources: [FETCH_METRICS, FETCH_ALERTS]
  },
  alertsHistorical: {
    resources: [FETCH_METRICS, FETCH_ALERT_NOTIFICATIONS]
  }
});

const initialState = Immutable.fromJS({
  view: {
    pages: {
      main: {
        notifications: {
          count: 0,
          message: ''
        },
        firstLoad: false,
        isResourcesReady: false
      },
      notificationGroups: {
        firstLoad: false,
        isResourcesReady: false
      },
      alertManager: {
        firstLoad: false,
        isResourcesReady: false
      },
      alertBuilder: {
        firstLoad: false,
        isResourcesReady: false,
        hasAlertAdded: false
      },
      alertBuilderTotal: {
        firstLoad: false,
        isResourcesReady: false,
        hasAlertAdded: false
      },
      alerts: {
        firstLoad: false,
        isResourcesReady: false
      },
      alertsHistorical: {
        firstLoad: false,
        isResourcesReady: false
      }
    }
  }
});

const validatePageLoadedResources = function (pages, state) {
  let newState = state;
  pages.forEach((page) => {
    const REQUIRED_RESOURCES = REQUIRED_RESOURCES_BY_PAGE.getIn([
      page,
      'resources'
    ]);
    let isResourcesReady = true;
    REQUIRED_RESOURCES.forEach((resource) => {
      if (!newState.getIn(['view', 'pages', page, 'resources', resource])) {
        isResourcesReady = false;
      }
    });
    newState = newState.setIn(
      ['view', 'pages', page, 'isResourcesReady'],
      isResourcesReady
    );
  });
  return newState;
};
const setPageLoadedResource = function (pages, state, action) {
  let newState = state;
  pages.forEach((page) => {
    newState = newState.setIn(
      ['view', 'pages', page, 'resources', action],
      true
    );
  });
  return validatePageLoadedResources(pages, newState);
};
const toggleResourceStatus = function (state, action) {
  const { pageName, resourceName } = action.payload;
  const newState = state.setIn(
    ['view', 'pages', pageName, 'resources', resourceName],
    !state.getIn(['view', 'pages', pageName, 'resources', resourceName])
  );
  return validatePageLoadedResources([pageName], newState);
};
const unsetWidgetCatalog = function (state) {
  return state.setIn(
    ['view', 'pages', 'alertBuilderTotal', 'resources', FETCH_WIDGET],
    false
  ).setIn(
    ['view', 'pages', 'alertBuilderTotal', 'firstLoad'],
    false
  ).setIn(
    ['view', 'pages', 'alertBuilderTotal', 'isResourcesReady'],
    false
  );
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SYSTEM_NOTIFICATION:
      return state
        .setIn(
          ['view', 'pages', 'main', 'notifications', 'count'],
          state.getIn(['view', 'pages', 'main', 'notifications', 'count']) + 1
        )
        .setIn(
          ['view', 'pages', 'main', 'notifications', 'message'],
          action.payload
        );
    case TOGGLE_PAGE_STATUS:
      return state.setIn(
        ['view', 'pages', action.payload, 'firstLoad'],
        !state.getIn(['view', 'pages', action.payload, 'firstLoad'])
      );
    case TOGGLE_RESOURCE_STATUS:
      return toggleResourceStatus(state, action);
    case FETCH_METRICS:
      return setPageLoadedResource(
        ['main', 'alertManager', 'alerts', 'alertsHistorical'],
        state,
        action.type
      );
    case FETCH_ALERT_NOTIFICATIONS:
      return setPageLoadedResource(['main', 'alertsHistorical'], state, action.type);
    case FETCH_ALERTS:
      return setPageLoadedResource(
        ['alerts'],
        state,
        action.type
      );
    case FETCH_METRIC_DATA:
      return setPageLoadedResource(['alertsHistorical'], state, action.type);
    case FETCH_NOTIFICATION_GROUPS:
      return setPageLoadedResource(
        ['notificationGroups', 'alertBuilder', 'alertBuilderTotal'],
        state,
        action.type
      );
    case FETCH_REPORTS:
      return setPageLoadedResource(['alertBuilder', 'alertManager', 'main'], state, action.type);
    case FETCH_WIDGET:
      return setPageLoadedResource(['alertBuilderTotal'], state, action.type);
    case TOGGLE_ADD_ALERT_STATUS:
      return state.setIn(
        ['view', 'pages', 'alertBuilder', 'hasAlertAdded'],
        !state.getIn(['view', 'pages', 'alertBuilder', 'hasAlertAdded'])
      ).setIn(
        ['view', 'pages', 'alertBuilderTotal', 'hasAlertAdded'],
        !state.getIn(['view', 'pages', 'alertBuilderTotal', 'hasAlertAdded'])
      );
    case CLEAR_WIDGET_CATALOG:
      return unsetWidgetCatalog(state);
    default:
      return state;
  }
};
