import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Nav, Navbar, Col, Row } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { SMALL_SCREEN_WIDTH } from '../Widgets/utils/catalogs';
import './Menu.scss';

/**
 * @class Menu
 * @extends {Component}
 */

class Menu extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    metrics: PropTypes.instanceOf(Immutable.List),
    notification_groups: PropTypes.object,
    pageTitle: PropTypes.string
  };

  static defaultProps = {
    metrics: new Immutable.List(),
    notification_groups: new Immutable.List(),
    pageTitle: ''
  };

  constructor(...args) {
    super(...args);

    this.state = {
      width: window.innerWidth
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  isMobile = () => {
    const { width } = this.state;
    return width <= SMALL_SCREEN_WIDTH;
  };

  renderMobile = () => (
    <div className="footer-nav">
      <Navbar className="mobile-nav" bg="white" fixed="bottom">
        <div className="d-flex flex-column">
          <ul>
            <li>
              <LinkContainer to={{ pathname: '/home' }}>
                <div>
                  <i className="fa fa-bell fa-2x"></i>
                  <div>Feed</div>
                </div>
              </LinkContainer>
            </li>
            <li>
              <LinkContainer to={{ pathname: '/AlertManager' }}>
                <div>
                  <i className="fa fa-list fa-2x"></i>
                  <div>Manager</div>
                </div>
              </LinkContainer>
            </li>
          </ul>
        </div>
      </Navbar>
    </div>
  );

  render() {
    const pathname = this.props.location ? this.props.location.pathname : null;
    let addButton;
    let pageTitle;
    if (this.isMobile()) {
      return this.renderMobile();
    }
    if (
      (pathname === '/AlertManager'
        && !this.props.metrics.isEmpty())
        || pathname === '/NotificationGroupBuilder'
    ) {
      addButton = (
        <Col>
          <div className="Menu-RightButton">
            <Link to="/AlertBuilder" className="btn btn-secondary">
              + Add Alert
            </Link>
          </div>
        </Col>
      );
    }
    if (
      pathname === '/NotificationGroup'
        && !this.props.notification_groups.isEmpty()
    ) {
      addButton = (
        <Col>
          <div className="Menu-RightButton">
            <Link to="/NotificationGroupBuilder" className="btn btn-secondary">
              + Add Notification Group
            </Link>
          </div>
        </Col>
      );
    }
    if (pathname === '/home') {
      pageTitle = 'Alert Feed';
    }
    if (
      pathname === '/AlertManager'
        || pathname === '/NotificationGroup'
        || pathname === '/NotificationGroupBuilder'
    ) {
      pageTitle = 'Alert Manager';
    }
    if (pathname === '/AlertBuilder') {
      pageTitle = 'Alert Builder';
    }
    if (pathname.startsWith('/Alerts')) {
      pageTitle = this.props.pageTitle;
    }
    return (
      <div>
        <Row>
          <Col>
            <Navbar expand="lg">
              <Navbar.Brand className="Menu-Navbar">{pageTitle}</Navbar.Brand>
              <Nav className="mr-auto">
                <LinkContainer to="/home">
                  <Nav.Link className="underline-nav">Feed</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/AlertManager">
                  <Nav.Link className="underline-nav">Manage Alerts</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/NotificationGroup">
                  <Nav.Link className="underline-nav">
                    Notification Groups
                  </Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar>
          </Col>
          {addButton}
        </Row>
        <Row>
          <hr className="Menu-divisor" />
        </Row>
      </div>
    );
  }
}

export default withRouter(Menu);
