import React from 'react';
import ReactDOM from 'react-dom';

// Required for Redux store setup
import configureStore from './redux/store';
import 'bootstrap-slider/dist/css/bootstrap-slider.css';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/base.scss';
import App from './containers/App';
import * as serviceWorker from './registerServiceWorker';
import setupFirebase from './setupFirebase';

ReactDOM.render(
  <App store={configureStore()} />,
  document.getElementById('root')
);

setupFirebase();
serviceWorker.unregister();
