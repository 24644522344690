import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setTokens, fetchProfile } from '../../redux/actions/profileActions';

import './Login.css';

/*
 * mapDispatchToProps
 */
const mapDispatchToProps = (dispatch) => ({
  setTokens: (tokens) => dispatch(setTokens(tokens)),
  fetchProfile: () => dispatch(fetchProfile())
});

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  ...state
});

/**
 * @class Login
 * @extends {Component}
 */
class LoginPage extends Component {
  static propTypes = {
    match: PropTypes.object,
    setTokens: PropTypes.func.isRequired,
    fetchProfile: PropTypes.func.isRequired
  };

  static defaultProps = {
    match: null
  };

  UNSAFE_componentWillMount() {
    const apiToken = this.props.match.params.apiToken
      ? decodeURIComponent(this.props.match.params.apiToken)
      : false;
    const csrfToken = this.props.match.params.csrfToken
      ? decodeURIComponent(this.props.match.params.csrfToken)
      : false;
    const legacyApiUrl = this.props.match.params.legacyApiUrl
      ? decodeURIComponent(this.props.match.params.legacyApiUrl)
      : false;
    const staqAppUrl = this.props.match.params.staqAppUrl
      ? decodeURIComponent(this.props.match.params.staqAppUrl)
      : false;
    if (apiToken && csrfToken && legacyApiUrl && staqAppUrl) {
      this.props.setTokens({
        csrf: csrfToken,
        api: apiToken,
        legacyApiUrl,
        staqAppUrl
      });
      this.props.fetchProfile();
    }
  }

  render() {
    const reportId = this.props.match.params.reportId
      ? decodeURIComponent(this.props.match.params.reportId)
      : false;
    const widgetId = this.props.match.params.widgetId
      ? decodeURIComponent(this.props.match.params.widgetId)
      : false;
    const alertId = this.props.match.params.alertId
      ? decodeURIComponent(this.props.match.params.alertId)
      : false;
    let redirectUrl = '/home';
    if (reportId) {
      redirectUrl = `/AlertBuilder/${reportId}`;
    }
    if (widgetId) {
      redirectUrl = `/AlertBuilder/widget/${widgetId}`;
    }
    console.log('redirectUrl', redirectUrl);
    if (alertId) {
      redirectUrl = `/Alerts/${alertId}`;
    }
    return <Redirect to={redirectUrl} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
