import Immutable from 'immutable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// eslint-disable-next-line
import JSONPretty from 'react-json-pretty';
import { setTokens, fetchProfile } from '../../redux/actions/profileActions';
// eslint-disable-next-line
import 'react-json-pretty/themes/monikai.css';

/*
 * mapDispatchToProps
 */
const mapDispatchToProps = (dispatch) => ({
  setTokens: (tokens) => dispatch(setTokens(tokens)),
  fetchProfile: () => dispatch(fetchProfile())
});

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  ...state
});

/**
 * @class TestPage
 * @extends {Component}
 */
class TestPage extends Component {
  static propTypes = {
    userProfileReducer: PropTypes.instanceOf(Immutable.Map).isRequired
  };

  constructor(...args) {
    super(...args);

    this.state = {
      method: '',
      action: '',
      requestBody: '',
      response: ''
    };
  }

  handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  getRequestHeader(userProfile) {
    return {
      'Content-Type': 'application/json',
      'X-Authentication-Token': this.getApiToken(userProfile),
      'X-CSRF-Token': this.getCsrfToken(userProfile)
    };
  }

  getCsrfToken(userProfile) {
    return userProfile.getIn(['profile', 'tokens', 'csrf']);
  }

  getApiToken(userProfile) {
    return userProfile.getIn(['profile', 'tokens', 'api']);
  }

  getLegacyApiUrl(userProfile) {
    return userProfile.getIn(['profile', 'tokens', 'legacyApiUrl']);
  }

  handleChangeMethod = (event) => {
    this.setState({
      method: event.currentTarget.value
    });
  };

  handleChangeAction = (event) => {
    this.setState({
      action: event.currentTarget.value
    });
  };

  handleChangeRequestBody = (event) => {
    this.setState({
      requestBody: event.currentTarget.value
    });
  };

  handleClick = () => {
    const { method, action, requestBody } = this.state;
    this.callAPI(action, method, requestBody);
  }

  callAPI = (endPoint, method, requestBody) => {
    const userProfile = this.props.userProfileReducer;
    console.log('API CALL', endPoint, method, requestBody);
    // call backend
    const requestOptions = method === 'GET'
      ? {
        method,
        headers: this.getRequestHeader(userProfile)
      }
      : {
        method,
        headers: this.getRequestHeader(userProfile),
        body: requestBody
      };
    fetch(`${this.getLegacyApiUrl(userProfile)}${endPoint}`, requestOptions)
      .then(this.handleErrors)
      .then((response) => response.json())
      .then((result) => {
        console.log('Success', result);
        this.setState({
          response: result
        });
        // call the Success function
      })
      .catch((error) => {
        console.log('Error', error);
        this.setState({
          response: error.message
        });
      });
  }

  render() {
    const FLUID = true;
    const displayRequestBody = this.state.method === 'GET' || this.state.method === ''
      ? { display: 'none' }
      : {};
    return (
      <Container fluid={FLUID} className="Page-Layout">
        <Row className="Page-Main">
          <Col>
            <Container>
              <Row>
                <Col align="center">
                  <Card className="NotificationGroup-Main">
                    <Card.Header>
                      <h6>SANDBOX FOR TESTING</h6>
                    </Card.Header>
                    <Card.Body>
                      <Table striped bordered responsive>
                        <thead>
                          <tr>
                            <th>METHOD</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Form.Group controlId="method">
                                <Form.Control
                                  as="select"
                                  onChange={this.handleChangeMethod}
                                  value={this.state.method}
                                >
                                  <option value="">Select a Method</option>
                                  <option value="GET">GET</option>
                                  <option value="POST">POST</option>
                                  <option value="PUT">PUT</option>
                                  <option value="DELETE">DELETE</option>
                                </Form.Control>
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group controlId="action">
                                <Form.Control
                                  as="select"
                                  onChange={this.handleChangeAction}
                                  value={this.state.action}
                                >
                                  <option value="">Select an Action</option>
                                  <option value="/users">/users</option>
                                  <option value="/notification_groups">/notification_groups</option>
                                  <option value="/reports?exclude_source_report_views=true&exclude_report_views_with_parents=true">/reports?exclude_source_report_views=true&exclude_report_views_with_parents=true</option>
                                  <option value="/alert_notifications">/alert_notifications</option>
                                  <option value="/alert_notifications/[id]">/alert_notifications/[id]</option>
                                  <option value="/alert_metrics">/alert_metrics</option>
                                  <option value="/alert_metrics/[id]">/alert_metrics/[id]</option>
                                  <option value="/alert_metric_histories">/alert_metric_histories</option>
                                  <option value="/alert_metrics/[id]/history">/alert_metrics/[id]/history</option>
                                  <option value="/alerts">/alerts</option>
                                  <option value="/dashboards">/dashboards</option>
                                  <option value="/dashboards/[id]">/dashboards/[id]</option>
                                  <option value="/widgets/[id]">/widgets/[id]</option>
                                </Form.Control>
                              </Form.Group>
                              <Form.Group controlId="action">
                                <Form.Control
                                  onChange={this.handleChangeAction}
                                  value={this.state.action}
                                >
                                </Form.Control>
                              </Form.Group>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" align="right">
                              <Button variant="secondary" onClick={this.handleClick}>Send</Button>
                            </td>
                          </tr>
                          <tr style={displayRequestBody}>
                            <td colSpan="2" align="center">
                              REQUEST BODY
                            </td>
                          </tr>
                          <tr style={displayRequestBody}>
                            <td colSpan="2" align="center">
                              <Form.Group controlId="requestBody">
                                <Form.Control
                                  as="textarea"
                                  rows="10"
                                  onChange={this.handleChangeRequestBody}
                                  value={this.state.requestBody}
                                />
                              </Form.Group>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" align="center">
                              RESPONSE BODY
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <Tabs defaultActiveKey="response">
                                <Tab eventKey="response" title="Response" style={{ marginTop: 20 }}>
                                  <Form.Group controlId="response">
                                    <Form.Control
                                      as="textarea"
                                      rows="15"
                                      value={JSON.stringify(this.state.response)}
                                    />
                                  </Form.Group>
                                </Tab>
                                <Tab eventKey="preview" title="Preview" style={{ marginTop: 20 }}>
                                  <JSONPretty
                                    id="json-pretty"
                                    data={this.state.response}
                                  >
                                  </JSONPretty>
                                </Tab>
                              </Tabs>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>

                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);
