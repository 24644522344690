import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { connect } from 'react-redux';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ReactBootstrapSlider from 'react-bootstrap-slider';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { schema, dimension as dimensionSchema } from './AlertBuilderSchema';
import { Card as CardRecord, DimensionColumn } from '../../redux/records';
import {
  FRECUENCY_OPTIONS,
  TIME_RANGE_LABELS,
  DATE_RANGE_LABELS,
  HOURLY_START_TIME,
  HOURLY_END_TIME,
  TOTAL_ALERT_TYPE,
  OPERATOR_LABELS,
  DIMENSION_OPERATOR_LABELS
} from '../Widgets/utils/catalogs';
import { setTempFormStatus } from '../../redux/actions/metricsActions';
import { toggleAddAlertStatus } from '../../redux/actions/viewActions';
import './AlertBuilder.scss';
import MultiSelect from '../Widgets/MultiSelect/MultiSelect';

const intialCard = new CardRecord();

/*
 * mapDispatchToProps
 */
const mapDispatchToProps = (dispatch) => ({
  setTempFormStatus: (status) => dispatch(setTempFormStatus(status)),
  toggleAddAlertStatus: () => dispatch(toggleAddAlertStatus())
});

const DISABLE_RELATIVE_THRESHOLD = true;

/*
 * mapStateToProps
 */
const mapStateToProps = (state) => ({
  ...state
});

const ValueContainerStyles = (base) => ({
  ...base,
  cursor: 'pointer',
  paddingLeft: 20
});

const ControlStyles = (base) => ({
  ...base,
  cursor: 'pointer',
  maxWidth: 219
});

const existInMetada = (dimension, columnsCatalog) => {
  const selectedDimension = columnsCatalog
    .find((c) => c.get('id') == dimension.get('dimension'));
  if (selectedDimension) {
    return true;
  }
  return false;
};

/**
 * @class AlertBuilderComponent
 * @extends {Component}
 */
class AlertBuilderComponent extends Component {
  static propTypes = {
    addMetric: PropTypes.func.isRequired,
    // eslint-disable-next-line
    history: PropTypes.object,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    catalogs: PropTypes.object.isRequired,
    notificationGroups: PropTypes.object.isRequired,
    setTempFormStatus: PropTypes.func.isRequired,
    toggleAddAlertStatus: PropTypes.func.isRequired,
    hasAlertAdded: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    const isEdition = props.location.params && props.location.params.metric;
    const isTempCard = props.location.tempCard !== undefined;
    let card = intialCard;
    let dimensionSchemas = Immutable.fromJS([]);
    let thresholdPrefix = '';
    let invalidMetric = false;
    let invalidDimension = false;
    let invalidDefiniton = false;
    const disableSubmit = false;
    if (isEdition) {
      card = props.location.params.metric;
      const columnsCatalog = card.get('widget') && card.get('widget').has('id')
        ? props.catalogs.getIn(['widget', 'columns'])
        : props.catalogs.get('columns');
      isEdition.get('selectedColumns').forEach((dimension) => {
        const updatedDimensionSchema = dimensionSchema.map((field, key) => {
          if (key === 'dimension') {
            const isInvalid = !existInMetada(dimension, columnsCatalog);
            if (isInvalid) {
              invalidDimension = true;
            }
            return field.set('isInvalid', isInvalid)
          }
          return field.set('isInvalid', false)
        })
        dimensionSchemas = dimensionSchemas.push(updatedDimensionSchema);
      });
      const selectedMetric = columnsCatalog
        .find((c) => c.get('id') == card.get('metric'));
      if (selectedMetric) {
        if (selectedMetric.get('type') == 'Percentage') {
          thresholdPrefix = '%';
        }
        if (selectedMetric.get('type') == 'Currency') {
          thresholdPrefix = '$';
        }
      } else {
        card = card.delete('metric');
        invalidMetric = true;
      }
    } else if (isTempCard) {
      card = props.location.tempCard;
    } else {
      card = intialCard;
    }
    let initialSchema = isEdition
      ? schema.setIn(
        ['form', 'fields'],
        schema.getIn(['form', 'fields']).map((field) => field.set('isInvalid', false))
      ).setIn(['form', 'fields', 'dimensions'], dimensionSchemas)
      : schema;

    if (invalidMetric) {
      initialSchema = initialSchema.setIn([
        'form',
        'fields',
        'metric',
        'isInvalid'
      ], true);
    }

    if (invalidMetric || invalidDimension || (isEdition && isEdition.get('broken'))) {
      initialSchema = initialSchema.setIn(['form', 'validated'], true);
      invalidDefiniton = true;
    }

    initialSchema = isTempCard
      ? this.props.location.tempSchema
      : initialSchema;
    const reportId = this.props.match.params.reportId
      ? decodeURIComponent(this.props.match.params.reportId)
      : false;
    if (reportId) {
      card = card.set('reportId', reportId)
      initialSchema = initialSchema.setIn(['form', 'fields', 'reportId', 'isInvalid'], false);
    }
    const widgetId = this.props.match.params.widgetId
      ? decodeURIComponent(this.props.match.params.widgetId)
      : false;
    if (widgetId) {
      card = card.setIn(['widget', 'id'], widgetId)
        .set('alert_type', TOTAL_ALERT_TYPE)
        .set('reportId', props.catalogs.getIn(['widget', 'report_id']));
      initialSchema = initialSchema.setIn(['form', 'fields', 'reportId', 'isInvalid'], false);
    }
    this.state = {
      card,
      schema: initialSchema,
      disableSubmit,
      thresholdPrefix,
      invalidDefiniton
    };
  }

  componentDidUpdate(prevProps) {
    const { hasAlertAdded, toggleAddAlertStatus, setTempFormStatus, history } = this.props;
    if (!prevProps.hasAlertAdded && hasAlertAdded) {
      toggleAddAlertStatus();
      setTempFormStatus(false);
      history.push('/home');
    }
  }

  setTimeZone(card) {
    return card.setIn(['alert_frequency', 'zone'], moment.tz.guess());
  }

  onAddCard = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { schema } = this.state;
    this.setState({
      disableSubmit: true
    }, () => {
      const isValidForm = schema
        .getIn(['form', 'fields'])
        .filter((field) => field.get('isInvalid'))
        .isEmpty();
      const areValidDimensions = schema
        .getIn(['form', 'fields', 'dimensions'])
        .map((dimension) => dimension
          .filter((field) => field.get('isInvalid')))
        .every((v) => v.isEmpty())
      if (isValidForm && areValidDimensions) {
        const newCard = this.setTimeZone(this.state.card);
        this.props.addMetric(newCard);
      } else {
        this.setState({
          disableSubmit: false,
          schema: schema.setIn(['form', 'validated'], true)
        });
      }
    });
  };

  onSetTempFormStatus = (event) => {
    this.props.setTempFormStatus(event);
  };

  handleChange = (event) => {
    const currentTargetId = event.currentTarget.id;
    const currentTargetValue = currentTargetId === 'notification_group_ids'
      ? this.getMultiSelectValue(event)
      : event.currentTarget.value;
    let card = this.handleMetricOperatorCardChange(currentTargetId);
    let metric = this.state.card.get('metric');
    let schema = this.handleMetricOperatorSchemaChange(currentTargetId, currentTargetValue);
    card = card.set(currentTargetId, currentTargetValue);
    if (currentTargetId === 'reportId') {
      [card, schema] = this.clearAllDimensions(card, schema)
      this.setState({ thresholdPrefix: '' });
    }
    if (currentTargetId === 'metric') {
      metric = event.currentTarget.value
      card = this.validateMetricThreshold(this.state.card.getIn(['metricThreshold', 0]), metric, card, 'metricThreshold');
      if (card.getIn(['metricThreshold', 1])) {
        card = this.validateMetricThreshold(card.getIn(['metricThreshold', 1]), metric, card, 'metricThresholdDown');
      }
    }
    if (schema.hasIn(['form', 'fields', currentTargetId])) {
      const isInvalid = this.validateField(event.currentTarget);
      this.setState({
        card,
        schema: schema.setIn(
          ['form', 'fields', currentTargetId, 'isInvalid'],
          isInvalid
        )
      });
    } else {
      this.setState({ card });
    }
  };

  isValidMetricThresholdDown = (displayThresholdDownSection) => {
    if (displayThresholdDownSection) {
      const metricThreshold = this.state.card.getIn(['metricThreshold']).get(1);
      return (metricThreshold && metricThreshold.length > 0)
    }
    return true;
  }

  validateMetricThreshold = (currentTargetValue, metric, card, currentTargetId) => {
    currentTargetValue = currentTargetValue.replace(/[^0-9.-]/g, '');
    if (typeof metric == 'string' && metric.length > 0) {
      let thresholdPrefix = '';
      const metricType = this.getColumnsCatalog()
        .find((c) => c.get('id') == metric)
        .get('type');
      if (metricType === 'Integer' || metricType === 'BigInteger') {
        currentTargetValue = currentTargetValue.replace('.', '').replace(/\D/, '')
      } else {
        // this regex allows just one decimal point
        // this regex allows just one negative symbol at the begining
        currentTargetValue = currentTargetValue.replace(/^(-?\d*\.?)|(\d*)\.?/g, '$1$2');
        if (metricType == 'Percentage') {
          thresholdPrefix = '%';
        }
        if (metricType == 'Currency') {
          thresholdPrefix = '$';
        }
      }
      this.setState({
        thresholdPrefix
      });
    }
    const index = currentTargetId === 'metricThreshold'
      ? 0
      : 1;
    const newCard = card.setIn(['metricThreshold', index], currentTargetValue)
    return newCard;
  };

  formatMetricThreshold = (currentTargetValue) => {
    const metric = this.state.card.get('metric');
    currentTargetValue = currentTargetValue.replace(/[^0-9.-]/g, '');
    if (typeof metric == 'string' && metric.length > 0) {
      const metricType = this.getColumnsCatalog()
        .find((c) => c.get('id') == metric)
        .get('type');
      if (metricType === 'Integer' || metricType === 'BigInteger') {
        currentTargetValue = currentTargetValue.replace('.', '').replace(/\D/, '')
      } else {
        // this regex allows just one negative symbol at the begining
        // this regex allows just one decimal point
        currentTargetValue = currentTargetValue.replace(/^(-?\d*\.?)|(\d*)\.?/g, '$1$2');
      }
    }
    return currentTargetValue;
  };

  clearAllDimensions = (card, schema) => {
    const newCard = card
      .set('selectedColumns', card.get('selectedColumns').clear())
      .set('date_range', undefined)
      .set('metricOperator', '')
      .set('metricThreshold', new Immutable.List(['']));
    const newSchema = schema.setIn(['form', 'fields', 'dimensions'], schema.getIn(['form', 'fields', 'dimensions']).clear())
    return [newCard, newSchema];
  };

  removeInput = (selectedColumn, index) => {
    const newCard = this.state.card.set('selectedColumns',
      this.state.card.get('selectedColumns').remove(index));
    const newSchema = this.state.schema.setIn(['form', 'fields', 'dimensions'],
      this.state.schema.getIn(['form', 'fields', 'dimensions']).remove(index));
    this.setState({
      card: newCard,
      schema: newSchema
    })
  };

  handleAddColumnChange = (i, event) => {
    const currentTargetId = event.currentTarget.id;
    const currentTargetValue = event.currentTarget.value;
    let selectedColumns = this.state.card.get('selectedColumns');
    const isInvalid = !this.state.schema
      .hasIn(['form', 'fields', 'dimensions', i, currentTargetId, 'required'])
        && event.currentTarget.value.length > 0

    selectedColumns = selectedColumns.setIn([i, currentTargetId], currentTargetValue);
    if (currentTargetId === 'dimension') {
      selectedColumns = selectedColumns.setIn([i, 'dimensionLabel'], event.currentTarget.label);
    }
    this.setState({
      card: this.state.card.setIn(['selectedColumns'], selectedColumns),
      schema: this.state.schema
        .setIn(['form', 'fields', 'dimensions', i, currentTargetId, 'isInvalid'], isInvalid)
    })
  };

  handleAlertFrequencyChange = (event) => {
    const currentTargetValue = event.currentTarget.value;
    let newCard = this.state.card.setIn(
      ['alert_frequency', 'frequency'],
      currentTargetValue
    );
    const alert_frequencyValidation = currentTargetValue === '';
    const startTime = this.state.card.getIn(['alert_frequency', 'startTime']);
    let alert_frequencyUnitRequired = false;
    let alert_frequencyUnitValidation = false;
    let alert_frequencyStartTimeRequired = false;
    let alert_frequencyStartTimeValidation = startTime === undefined || startTime === '';
    if (currentTargetValue === 'hourly') {
      const daysOfWeek = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday'
      ];
      newCard = newCard
        .setIn(['alert_frequency', 'startTime'], HOURLY_START_TIME)
        .setIn(['alert_frequency', 'endTime'], HOURLY_END_TIME)
        .setIn(['alert_frequency', 'daysOfWeek'], Immutable.fromJS(daysOfWeek))
        .setIn(['alert_frequency', 'daysOfMonth'], Immutable.fromJS([]));
      alert_frequencyStartTimeValidation = false
    } else if (currentTargetValue === 'weekly' || currentTargetValue === 'monthly') {
      alert_frequencyUnitRequired = true;
      alert_frequencyUnitValidation = true;
      alert_frequencyStartTimeRequired = true;
    } else { // daily
      newCard = newCard
        .setIn(['alert_frequency', 'daysOfWeek'], Immutable.fromJS([]))
        .setIn(['alert_frequency', 'daysOfMonth'], Immutable.fromJS([]));
      alert_frequencyUnitRequired = true;
      alert_frequencyUnitValidation = startTime === undefined || startTime === '';
    }
    this.setState({
      card: newCard,
      schema: this.state.schema
        .setIn(
          ['form', 'fields', 'alert_frequency', 'isInvalid'],
          alert_frequencyValidation
        )
        .setIn(
          ['form', 'fields', 'alert_frequencyUnit', 'required'],
          alert_frequencyUnitRequired
        )
        .setIn(
          ['form', 'fields', 'alert_frequencyUnit', 'isInvalid'],
          alert_frequencyUnitValidation
        )
        .setIn(
          ['form', 'fields', 'alert_frequencyStartTime', 'required'],
          alert_frequencyStartTimeRequired
        )
        .setIn(
          ['form', 'fields', 'alert_frequencyStartTime', 'isInvalid'],
          alert_frequencyStartTimeValidation
        )
    });
  };

  handleMetricOperatorSchemaChange = (currentTargetId, currentTargetValue) => {
    if (currentTargetId !== 'metricOperator') {
      return this.state.schema;
    }
    const displayThresholdDownSection = (currentTargetValue === 'Between' || currentTargetValue === 'NotBetween');
    return this.state.schema.setIn(['form', 'fields', 'metricThresholdDown', 'required'], displayThresholdDownSection)
      .setIn(['form', 'fields', 'metricThresholdDown', 'isInvalid'], !this.isValidMetricThresholdDown(displayThresholdDownSection));
  }

  handleMetricOperatorCardChange = (currentTargetId) => {
    if (currentTargetId !== 'metricOperator') {
      return this.state.card;
    }
    return this.state.card
      .setIn(['metricThreshold'], new Immutable.List(['']));
  }

  handleMetricThresholdChange = (event) => {
    const currentTargetValue = event.currentTarget.value;
    const metricThresholdDown = this.state.card.getIn(['metricThreshold']).get(1);
    const formattedTargetValue = this.formatMetricThreshold(currentTargetValue);
    const isInvalidMetricThreshold = !formattedTargetValue.length > 0;
    this.setState({
      card: this.state.card
        .setIn(['metricThreshold'], Immutable.fromJS([formattedTargetValue, metricThresholdDown])),
      schema: this.state.schema
        .setIn(['form', 'fields', 'metricThreshold', 'isInvalid'], isInvalidMetricThreshold)
    });
  };

  handleMetricThresholdChangeSelect = (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option' || actionMeta.action === 'clear') {
      const currentTargetValue = newValue ? newValue.value : '';
      const metricThresholdDown = this.state.card.getIn(['metricThreshold']).get(1);
      const formattedTargetValue = this.formatMetricThreshold(currentTargetValue);
      const isInvalidMetricThreshold = !formattedTargetValue.length > 0;
      this.setState({
        card: this.state.card
          .setIn(['metricThreshold'], Immutable.fromJS([formattedTargetValue, metricThresholdDown])),
        schema: this.state.schema
          .setIn(['form', 'fields', 'metricThreshold', 'isInvalid'], isInvalidMetricThreshold)
      });
    }
    if (actionMeta.action === 'select-option') {
      const currentTargetValue = {
        column: newValue.label,
        id: newValue.value,
        columnType: 'metric'
      };
      const metricThresholdDown = this.state.card.getIn(['metricThreshold']).get(1);
      this.setState({
        card: this.state.card
          .setIn(['metricThreshold'], Immutable.fromJS([currentTargetValue, metricThresholdDown])),
        schema: this.state.schema
          .setIn(['form', 'fields', 'metricThreshold', 'isInvalid'], false)
      });
    }
  };

  handleMetricThresholdDownChange = (event) => {
    const currentTargetValue = event.currentTarget.value;
    const metricThreshold = this.state.card.getIn(['metricThreshold']).get(0);
    const formattedTargetValue = this.formatMetricThreshold(currentTargetValue);
    const isInvalidMetricThreshold = !formattedTargetValue.length > 0;
    this.setState({
      card: this.state.card
        .setIn(['metricThreshold'], Immutable.fromJS([metricThreshold, formattedTargetValue])),
      schema: this.state.schema
        .setIn(['form', 'fields', 'metricThresholdDown', 'isInvalid'], isInvalidMetricThreshold)
    });
  };

  handleAlertFrequencyUnitChange = (event) => {
    const currentTargetValue = event.currentTarget.value;
    const alert_frequency = this.state.card.getIn([
      'alert_frequency',
      'frequency'
    ]);
    if (alert_frequency === 'daily') {
      this.handleAlertFrequencyHourChange(event)
    } else {
      const daysOfWeek = alert_frequency === 'weekly' ? [currentTargetValue] : [];
      const daysOfMonth = alert_frequency === 'monthly' ? [currentTargetValue] : [];
      const alert_frequencyUnitValidation = currentTargetValue === '';
      this.setState({
        card: this.state.card
          .setIn(['alert_frequency', 'daysOfWeek'], Immutable.fromJS(daysOfWeek))
          .setIn(
            ['alert_frequency', 'daysOfMonth'],
            Immutable.fromJS(daysOfMonth)
          ),
        schema: this.state.schema.setIn(
          ['form', 'fields', 'alert_frequencyUnit', 'isInvalid'],
          alert_frequencyUnitValidation
        )
      });
    }
  };

  handleAlertFrequencyHourChange = (event) => {
    const currentTargetValue = event.target.value;
    const alert_frequencyUnitValidation = currentTargetValue === '';
    const alert_frequency = this.state.card.getIn([
      'alert_frequency',
      'frequency'
    ]);
    let alertFrequencyType = '';
    let newSchema = this.state.schema
    if (alert_frequency === 'daily') {
      alertFrequencyType = 'alert_frequencyUnit';
      // also set the alert_frequencyStartTime
      newSchema = newSchema
        .setIn(
          ['form', 'fields', 'alert_frequencyStartTime', 'isInvalid'],
          alert_frequencyUnitValidation
        );
    } else {
      alertFrequencyType = 'alert_frequencyStartTime';
    }
    newSchema = newSchema
      .setIn(
        ['form', 'fields', alertFrequencyType, 'isInvalid'],
        alert_frequencyUnitValidation
      );
    const newCard = this.state.card
      .setIn(['alert_frequency', 'startTime'], currentTargetValue)
      .setIn(['alert_frequency', 'endTime'], currentTargetValue);
    this.setState({
      card: newCard,
      schema: newSchema
    });
  }

  validateField = (field) => {
    if (field === 'alert_frequency' || field === 'metricThreshold' || field === 'metricThresholdDown') {
      return true;
    }
    if (
      field.id === 'date_range'
      || field.id === 'description'
    ) {
      return false;
    }
    if (
      this.state.schema.hasIn(['form', 'fields', field.id, 'required'])
      && field.value !== undefined
      && field.value.toString().length > 0
    ) {
      return false;
    }
    return true;
  };

  validateCheckbox = (field) => {
    if (
      (this.state.schema.hasIn(['form', 'fields', field.id, 'required'])
        && this.state.schema.getIn(['form', 'fields', field.id, 'required'])
        && field.checked)
        || !this.state.schema.getIn(['form', 'fields', field.id, 'required'])
    ) {
      return false;
    }
    return true;
  };

  getMultiSelectValue = (event) => Immutable.fromJS(event.currentTarget.value);

  toogleChange = (event) => {
    const isInvalid = this.validateCheckbox(event.currentTarget);
    this.setState({
      card: this.state.card.set(
        event.currentTarget.id,
        !this.state.card.get(event.currentTarget.id)
      ),
      schema: this.state.schema.setIn(
        ['form', 'fields', event.currentTarget.id, 'isInvalid'],
        isInvalid
      )
    });
  };

  changeTimeRangeValue = (e) => {
    const newCard = this.state.card
      .setIn(['alert_frequency', 'startTime'], e.target.value[0])
      .setIn(['alert_frequency', 'endTime'], e.target.value[1]);
    this.setState({ card: newCard });
  };

  changeDayValue = (e) => {
    let daysOfWeek = this.state.card.getIn(['alert_frequency', 'daysOfWeek']);
    daysOfWeek = daysOfWeek.includes(e.target.value)
      ? daysOfWeek.filter((d) => d !== e.target.value)
      : daysOfWeek.concat(e.target.value);
    this.setState({
      card: this.state.card.setIn(['alert_frequency', 'daysOfWeek'], daysOfWeek)
    });
  };

  setPresetDaysValues = (e) => {
    const daysOfWeek = e.target.value === 'everyday'
      ? [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
      ]
      : ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
    const newCard = this.state.card
      .setIn(['alert_frequency', 'startTime'], HOURLY_START_TIME)
      .setIn(['alert_frequency', 'endTime'], HOURLY_END_TIME)
      .setIn(['alert_frequency', 'daysOfWeek'], Immutable.fromJS(daysOfWeek));

    this.setState({ card: newCard });
  };

  renderDateRangeSection = () => {
    const selectedCatalogReport = this.getColumnsCatalog();
    if (selectedCatalogReport.size <= 0) {
      return null;
    }
    const isReportDateless = !selectedCatalogReport.map((selectedCatalogColumn) => {
      if (
        selectedCatalogColumn.get('column_id').toUpperCase() === 'DATE'
      ) {
        return true;
      }
      return (
        false
      );
    }).includes(true);
    if (isReportDateless) {
      return null;
    }
    let dateRangeOptionsValue = { value: '', label: 'Select Date Range (optional)' };
    const dateRangeOptionsArray = [dateRangeOptionsValue];
    DATE_RANGE_LABELS.forEach((dateRange) => {
      const newOption = { value: dateRange, label: dateRange };
      dateRangeOptionsArray.push(newOption);
      if (dateRange == this.state.card.get('date_range')) {
        dateRangeOptionsValue = newOption;
      }
    });

    return (
      <Col xs={4}>
        <Form.Group controlId="date_range" align="left">
          <Select
            options={dateRangeOptionsArray}
            value={dateRangeOptionsValue}
            onChange={(value) => {
              this.handleChange({
                currentTarget: {
                  id: 'date_range',
                  value: value.value
                }
              })
            }}
          />
        </Form.Group>
      </Col>
    );
  }

  renderMetricThresholdSection = () => {
    const metricThreshold = this.state.card.metricThreshold.first();
    let thresholdPrefix = this.state.thresholdPrefix;
    const metricThresholdPlaceholder = (this.state.card.metricOperator === 'Between' || this.state.card.metricOperator === 'NotBetween')
      ? 'Value'
      : 'Threshold';
    const isBetweenAndNotBetween = this.state.card.metricOperator === 'Between' || this.state.card.metricOperator === 'NotBetween';
    if (isBetweenAndNotBetween || DISABLE_RELATIVE_THRESHOLD) {
      let inputStyle = { width: '106px', height: '38px' };
      let thresholdPrefixClassName = 'thresholdPrefix-right';
      if (!isBetweenAndNotBetween) {
        inputStyle = { width: '218px', height: '38px', marginRight: '12px' };
        thresholdPrefixClassName = 'thresholdPrefix-right-input';
      }
      return (
        <Form.Group controlId="metricThreshold">
          <div
            className={` thresholdPrefix ${this.state.thresholdPrefix == '%' ? thresholdPrefixClassName : ''}`}
          >
            <Form.Control
              placeholder={metricThresholdPlaceholder}
              onChange={this.handleMetricThresholdChange}
              value={metricThreshold}
              style={inputStyle}
              isInvalid={this.isInvalidField('metricThreshold')}
            />
            <i>{this.state.thresholdPrefix}</i>
          </div>
          <Form.Control.Feedback
            type="invalid"
            style={{ display: this.isInvalidField('metricThreshold') ? 'block' : '' }}
          >
            Please provide a value.
          </Form.Control.Feedback>
        </Form.Group>
      );
    }
    let metricThresholdValue = null;
    if (typeof metricThreshold == 'string' && metricThreshold.length > 0) {
      metricThresholdValue = {
        value: metricThreshold,
        label: metricThreshold };
    }
    if (typeof metricThreshold == 'object') {
      metricThresholdValue = {
        value: metricThreshold.get('id'),
        label: metricThreshold.get('column') };
      thresholdPrefix = '';
    }
    const columnsCatalog = this.getColumnsCatalog();
    const metricsOptionsArray = [];
    columnsCatalog.forEach((selectedCatalogColumn) => {
      if (selectedCatalogColumn.get('column_type') === 'computed_metric'
        || selectedCatalogColumn.get('column_type') === 'metric'
      ) {
        const newOption = { value: selectedCatalogColumn.get('id'), label: selectedCatalogColumn.get('label') };
        metricsOptionsArray.push(newOption);
      }
    });
    return (
      <Form.Group controlId="metricThreshold" align="left">
        <div className={` thresholdPrefix ${this.state.thresholdPrefix == '%' ? 'thresholdPrefix-right-select' : ''}`}>
          <CreatableSelect
            isClearable
            onChange={this.handleMetricThresholdChangeSelect}
            onInputChange={this.handleInputChange}
            styles={{ valueContainer: ValueContainerStyles, control: ControlStyles }}
            className={this.isInvalidField('metricThreshold') ? 'invalidFiled' : ''}
            value={metricThresholdValue}
            options={metricsOptionsArray}
            placeholder={metricThresholdPlaceholder}
            formatCreateLabel={(inputValue) => inputValue}
          />
          <i>{thresholdPrefix}</i>
        </div>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: this.isInvalidField('metricThreshold') ? 'block' : '' }}
        >
          Please provide a threshold.
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  renderMetricThresholdDownSection = () => {
    if (this.state.card.metricOperator !== 'Between' && this.state.card.metricOperator !== 'NotBetween') {
      return null;
    }
    const metricThresholdDown = this.state.card.metricThreshold.get(1)
      ? this.state.card.metricThreshold.get(1)
      : '';
    return (
      <Form.Group controlId="metricThresholdDown">
        <div className={` thresholdPrefix ${this.state.thresholdPrefix == '%' ? 'thresholdPrefix-right' : ''}`}>
          <Form.Control
            placeholder="Value"
            onChange={this.handleMetricThresholdDownChange}
            value={metricThresholdDown}
            isInvalid={this.isInvalidField('metricThresholdDown')}
            style={{ marginLeft: 5, width: '106px', height: '38px' }}
          />
          <i>{this.state.thresholdPrefix}</i>
        </div>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: this.isInvalidField('metricThresholdDown') ? 'block' : '' }}
        >
          Please provide a value.
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  renderHourlySection = () => {
    const alert_frequency = this.state.card.getIn([
      'alert_frequency',
      'frequency'
    ]);
    if (alert_frequency === undefined || alert_frequency === '') {
      return null;
    }
    let {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday
    } = false;
    const daysOfWeek = this.state.card.getIn(['alert_frequency', 'daysOfWeek']);
    if (daysOfWeek) {
      monday = daysOfWeek.includes('monday');
      tuesday = daysOfWeek.includes('tuesday');
      wednesday = daysOfWeek.includes('wednesday');
      thursday = daysOfWeek.includes('thursday');
      friday = daysOfWeek.includes('friday');
      saturday = daysOfWeek.includes('saturday');
      sunday = daysOfWeek.includes('sunday');
    }
    const startTime = this.state.card.getIn(['alert_frequency', 'startTime']);
    const endTime = this.state.card.getIn(['alert_frequency', 'endTime']);
    const frequencySundayStyle = sunday ? 'btn-primary' : 'btn-secondary';
    const frequencyMondayStyle = monday ? 'btn-primary' : 'btn-secondary';
    const frequencyTuesdayStyle = tuesday ? 'btn-primary' : 'btn-secondary';
    const frequencyWednesdayStyle = wednesday ? 'btn-primary' : 'btn-secondary';
    const frequencyThursdayStyle = thursday ? 'btn-primary' : 'btn-secondary';
    const frequencyFridayStyle = friday ? 'btn-primary' : 'btn-secondary';
    const frequencySaturdayStyle = saturday ? 'btn-primary' : 'btn-secondary';
    const frequencyEverydayStyle = monday
      && tuesday
      && wednesday
      && thursday
      && friday
      && saturday
      && sunday
      && startTime === HOURLY_START_TIME
      && endTime === HOURLY_END_TIME
      ? 'btn-primary'
      : 'btn-secondary';
    const frequencyWeekdaysStyle = monday
      && tuesday
      && wednesday
      && thursday
      && friday
      && !saturday
      && !sunday
      && startTime === HOURLY_START_TIME
      && endTime === HOURLY_END_TIME
      ? 'btn-primary'
      : 'btn-secondary';

    if (alert_frequency === 'hourly') {
      return (
        <Row>
          <Col>
            {alert_frequency === 'hourly' && (
              <div>
                <ButtonToolbar>
                  <Button
                    onClick={this.setPresetDaysValues}
                    value="everyday"
                    className={`${frequencyEverydayStyle} AlertBuilder-FrequencyButton`}
                  >
                    Everyday (9 - 5 pm)
                  </Button>
                  <Button
                    onClick={this.setPresetDaysValues}
                    value="weekdays"
                    className={`${frequencyWeekdaysStyle} AlertBuilder-FrequencyButton`}
                  >
                    Weekdays (9 - 5 pm)
                  </Button>
                </ButtonToolbar>
                <br />
                <ButtonToolbar>
                  <Button
                    value="sunday"
                    onClick={this.changeDayValue}
                    className={`${frequencySundayStyle} AlertBuilder-FrequencyButton`}
                  >
                    S
                  </Button>
                  <Button
                    value="monday"
                    onClick={this.changeDayValue}
                    className={`${frequencyMondayStyle} AlertBuilder-FrequencyButton`}
                  >
                    M
                  </Button>
                  <Button
                    value="tuesday"
                    onClick={this.changeDayValue}
                    className={`${frequencyTuesdayStyle} AlertBuilder-FrequencyButton`}
                  >
                    T
                  </Button>
                  <Button
                    value="wednesday"
                    onClick={this.changeDayValue}
                    className={`${frequencyWednesdayStyle} AlertBuilder-FrequencyButton`}
                  >
                    W
                  </Button>
                  <Button
                    value="thursday"
                    onClick={this.changeDayValue}
                    className={`${frequencyThursdayStyle} AlertBuilder-FrequencyButton`}
                  >
                    TH
                  </Button>
                  <Button
                    value="friday"
                    onClick={this.changeDayValue}
                    className={`${frequencyFridayStyle} AlertBuilder-FrequencyButton`}
                  >
                    F
                  </Button>
                  <Button
                    value="saturday"
                    onClick={this.changeDayValue}
                    className={`${frequencySaturdayStyle} AlertBuilder-FrequencyButton`}
                  >
                    SA
                  </Button>
                </ButtonToolbar>
              </div>
            )}
            <br />
            <div className="AlertBuilder-SliderContainer">
              <div className="AlertBuilder-SliderMain">
                <div className="AlertBuilder-SliderLeftLabel">
                  {TIME_RANGE_LABELS[startTime]}
                </div>
                <ReactBootstrapSlider
                  max={HOURLY_END_TIME}
                  min={HOURLY_START_TIME}
                  id="alertFrequency"
                  value={[
                    startTime || HOURLY_START_TIME,
                    endTime || HOURLY_END_TIME
                  ]}
                  step={100}
                  change={this.changeTimeRangeValue}
                />
                <div className="AlertBuilder-SliderRightLabel">
                  {TIME_RANGE_LABELS[endTime]}
                </div>
              </div>
            </div>
            <br />
          </Col>
        </Row>
      );
    }
    if (alert_frequency !== 'hourly'
      && alert_frequency !== 'daily') {
      let hourOptionsValue = { value: '', label: 'Choose an option' };
      const hourOptionsArray = [];
      FRECUENCY_OPTIONS.find(
        (frequency) => frequency.get('value') === 'daily'
      )
        .get('options').forEach((option) => {
          const newOption = { value: option.get('value'), label: option.get('label') };
          hourOptionsArray.push(newOption);
          if (option.get('value') == this.state.card.getIn(['alert_frequency', 'startTime'])) {
            hourOptionsValue = newOption;
          }
        });
      return (
        <Row>
          <Col>
            <Select
              className={this.isInvalidField('alert_frequencyStartTime') ? 'invalidFiled' : ''}
              options={hourOptionsArray}
              value={hourOptionsValue}
              onChange={(value) => {
                this.handleAlertFrequencyHourChange({
                  currentTarget: {
                    value: value.value
                  },
                  target: {
                    value: value.value
                  }
                })
              }}
            />
            <Form.Group
              controlId="alert_frequencyUnit"
            >
              <Form.Control.Feedback
                type="invalid"
                style={{ display: this.isInvalidField('alert_frequencyStartTime') ? 'block' : '' }}
              >
                Please provide a time.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col />
        </Row>
      )
    }
    return null;
  }

  appendInput = (e) => {
    e.preventDefault()

    const newDimension = dimensionSchema;
    const existingDimensions = this.state.schema
      .getIn(['form', 'fields', 'dimensions']);
    const dimensions = existingDimensions
      .push(newDimension);
    const schema = this.state.schema
      .setIn(['form', 'fields', 'dimensions'], dimensions);
    const newSelectedColumn = new DimensionColumn();
    const card = this.state.card
      .set('selectedColumns',
        this.state.card
          .get('selectedColumns')
          .concat([newSelectedColumn]));

    this.setState({ card, schema });
  }

  addConditionButton = () => {
    const selectedColumnSize = this.state.card.selectedColumns.size;
    const columnsCatalog = this.getColumnsCatalog();
    let dimensionCount = 0;
    columnsCatalog.map((selectedCatalogColumn) => {
      if (
        (selectedCatalogColumn.get('column_type') === 'dimension')
        && selectedCatalogColumn.get('type').toUpperCase() !== 'DATE'
      ) {
        dimensionCount += 1;
      }
      return dimensionCount;
    });
    return (
      <div>
        { selectedColumnSize < dimensionCount && (
          <Button
            className="btn btn-secondary addConditionButton"
            onClick={this.appendInput}
          >
            + Add Condition
          </Button>
        )}
      </div>
    );
  }

  getColumnsCatalog = () => {
    if (this.isTotalTypeAlert()) {
      return this.props.catalogs.getIn(['widget', 'columns']);
    }
    const selectedCatalogReport = this.props.catalogs
      .get('reports')
      .find((report) => report.get('id') == this.state.card.reportId);
    const columnsCatalog = this.props.catalogs.get('columns');
    let reportColumns = new Immutable.List();
    if (selectedCatalogReport) {
      selectedCatalogReport.get('column_ids').forEach((column) => {
        const selectedCatalogColumn = columnsCatalog.find(
          (c) => c.get('id') == column
        );
        if (selectedCatalogColumn) {
          reportColumns = reportColumns.push(selectedCatalogColumn);
        }
      });
    }
    return reportColumns;
  }

  buildDimensionThresholdValue = (selectedColumn) => {
    if (!selectedColumn.get('dimensionThreshold')) {
      return '';
    }
    if (typeof selectedColumn.get('dimensionThreshold') == 'object') {
      return selectedColumn.get('dimensionThreshold').first();
    }
    return selectedColumn.get('dimensionThreshold');
  }

  buildDimensionOperatorDropdownValue = (selectedColumn) => {
    if (!selectedColumn.get('dimensionOperator')) {
      return { value: '', label: 'Select a Operator' };
    }
    return { value: selectedColumn.get('dimensionOperator'), label: DIMENSION_OPERATOR_LABELS[selectedColumn.get('dimensionOperator')] };
  }

  buildDimensionOperatorDropdown = () => {
    const operatorOptionsValue = { value: '', label: 'Select a Operator' };
    const operatorOptionsArray = [operatorOptionsValue];
    Immutable.Map(DIMENSION_OPERATOR_LABELS).forEach((text, value) => {
      const newOption = { value, label: text };
      operatorOptionsArray.push(newOption);
    });
    return operatorOptionsArray;
  }

  buildDimensionDropdownValue = (selectedColumn) => {
    const columnsCatalog = this.getColumnsCatalog();
    const selectedCatalogColumn = columnsCatalog.find(
      (c) => c.get('id') == selectedColumn.get('dimension')
    );
    if (!selectedColumn.get('dimension') || !selectedCatalogColumn) {
      return { value: '', label: 'Select a Dimension' };
    }
    return { value: selectedColumn.get('dimension'), label: selectedCatalogColumn.get('label') };
  }

  buildDimensionDropdown = (i) => {
    const dimesionsOptionsValue = { value: '', label: 'Select a Dimension' };
    const dimesionsOptionsArray = [dimesionsOptionsValue];
    const dimensionIds = this.state.card.selectedColumns.map((c) => c.dimension);
    const columnsCatalog = this.getColumnsCatalog();
    columnsCatalog.forEach((selectedCatalogColumn) => {
      const columnId = String(selectedCatalogColumn.get('id'));
      let addOption = true;
      if (
        !(selectedCatalogColumn.get('column_type') === 'dimension')
        || selectedCatalogColumn.get('type').toUpperCase() === 'DATE'
        || (dimensionIds.get(i) != selectedCatalogColumn.get('id') && dimensionIds.includes(columnId))
      ) {
        addOption = false;
      }
      if (addOption) {
        const newOption = { value: selectedCatalogColumn.get('id'), label: selectedCatalogColumn.get('label') };
        dimesionsOptionsArray.push(newOption);
      }
    });
    return (dimesionsOptionsArray);
  }

  handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      this.handleMetricThresholdChangeSelect({ label: inputValue, value: inputValue }, { action: 'create-option' });
    }
  };

  isInvalidFieldPath = (path) => this.state.schema.getIn(['form', 'validated'])
    && this.state.schema.getIn(path);

  isInvalidField = (fieldId) => this.isInvalidFieldPath([
    'form',
    'fields',
    fieldId,
    'isInvalid'
  ]);

  isTotalTypeAlert = () => {
    if (this.state.card.get('widget')) {
      return this.state.card.get('widget').has('id');
    }
    return false;
  };

  getWidget = () => this.props.catalogs.get('widget');

  renderReportComponent = () => {
    if (this.isTotalTypeAlert()) {
      const widget = this.getWidget();
      return (
        <div>
          <Form.Label style={{ marginRight: 5 }}>Widget name:</Form.Label>
          {widget.get('name')}
        </div>
      );
    }
    let reportsOptionsValue = { value: '', label: 'Select a Report' };
    const reportsOptionsArray = [reportsOptionsValue];
    this.props.catalogs
      .get('reports')
      .filter((report) => report.get('report_type') !== 'notification_group'
        || report.get('id') == this.state.card.reportId)
      .sort((a, b) => a.get('name').localeCompare(b.get('name')))
      .forEach((report) => {
        const newOption = { value: report.get('id').toString(), label: report.get('name') };
        reportsOptionsArray.push(newOption);
        if (report.get('id') == this.state.card.reportId) {
          reportsOptionsValue = newOption;
        }
      });
    return (
      <div>
        <Select
          isDisabled={this.props.match.params.reportId}
          className={this.isInvalidField('reportId') ? 'invalidFiled' : ''}
          options={reportsOptionsArray}
          value={reportsOptionsValue}
          onChange={(value) => {
            this.handleChange({
              currentTarget: {
                id: 'reportId',
                value: value.value.toString()
              }
            })
          }}
        />
        <Form.Control.Feedback
          type="invalid"
          style={{ display: this.isInvalidField('reportId') ? 'block' : '' }}
        >
          Please select a report.
        </Form.Control.Feedback>
      </div>
    );
  };

  renderInvalidDefinition = () => {
    if (!this.state.invalidDefiniton) {
      return null;
    }
    return (
      <Row>
        <Col>
          <Form.Group align="left">
            <Form.Label className="invalidDefiniton">
              Source of this alert has changed after its creation,
              please adjust the parameters and save it.
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
    );
  }

  render() {
    const columnsCatalog = this.getColumnsCatalog();
    let metricsOptionsValue = { value: '', label: 'Select a Metric' };
    const metricsOptionsArray = [];
    columnsCatalog.forEach((selectedCatalogColumn) => {
      if (selectedCatalogColumn.get('column_type') === 'computed_metric'
        || selectedCatalogColumn.get('column_type') === 'metric'
      ) {
        const newOption = { value: selectedCatalogColumn.get('id'), label: selectedCatalogColumn.get('label') };
        metricsOptionsArray.push(newOption);
        if (selectedCatalogColumn.get('id') == this.state.card.metric) {
          metricsOptionsValue = newOption;
        }
      }
    });
    const notificationGroupsOptions = this.props.notificationGroups.map(
      (notificationGroup) => (
        { value: notificationGroup.get('id'), label: notificationGroup.get('groupName') }
      )
    );
    const isMetricEnabledInvalid = this.state.schema.getIn(['form', 'validated'])
      && this.state.schema.getIn(['form', 'fields', 'enabled', 'isInvalid']);
    const enabledClassNane = isMetricEnabledInvalid
      ? 'AlertBuilder-FormInput-Invalid-Label'
      : '';

    let frequencyOptionsValue = { value: '', label: 'Select Frequency' };
    const frequencyOptionsArray = [frequencyOptionsValue];
    FRECUENCY_OPTIONS.forEach((frequency) => {
      const newOption = { value: frequency.get('value'), label: frequency.get('label') };
      frequencyOptionsArray.push(newOption);
      if (frequency.get('value') == this.state.card.getIn(['alert_frequency', 'frequency'])) {
        frequencyOptionsValue = newOption;
      }
    });

    const alert_frequency = this.state.card.getIn([
      'alert_frequency',
      'frequency'
    ])
      ? this.state.card.getIn(['alert_frequency', 'frequency'])
      : 'hourly';
    let alert_frequencyUnit = '';
    if (
      alert_frequency === 'daily'
        && this.state.card.hasIn(['alert_frequency', 'startTime'])
    ) {
      alert_frequencyUnit = this.state.card
        .getIn(['alert_frequency', 'startTime'])
    }
    if (
      alert_frequency === 'weekly'
        && this.state.card.hasIn(['alert_frequency', 'daysOfWeek'])
    ) {
      alert_frequencyUnit = this.state.card
        .getIn(['alert_frequency', 'daysOfWeek'])
        .first();
    }
    if (
      alert_frequency === 'monthly'
        && this.state.card.hasIn(['alert_frequency', 'daysOfMonth'])
    ) {
      alert_frequencyUnit = this.state.card
        .getIn(['alert_frequency', 'daysOfMonth'])
        .first();
    }

    let subFrecuencyOptionsValue = { value: '', label: 'Choose an option' };
    const subFrecuencyOptionsArray = [];
    FRECUENCY_OPTIONS.find(
      (frequency) => frequency.get('value') === alert_frequency
    )
      .get('options').forEach((option) => {
        const newOption = { value: option.get('value'), label: option.get('label') };
        subFrecuencyOptionsArray.push(newOption);
        if (option.get('value') == alert_frequencyUnit) {
          subFrecuencyOptionsValue = newOption;
        }
      });
    const alert_frequencyDisplayStyle = subFrecuencyOptionsArray.length > 0 ? 'block' : 'none';

    let operatorOptionsValue = { value: '', label: 'Select a Operator' };
    const operatorOptionsArray = [operatorOptionsValue];
    Immutable.Map(OPERATOR_LABELS).forEach((text, value) => {
      const newOption = { value, label: text };
      operatorOptionsArray.push(newOption);
      if (value == this.state.card.metricOperator) {
        operatorOptionsValue = newOption;
      }
    });

    const thresholdRowStyle = (
      this.state.card.metricOperator === 'Between' || this.state.card.metricOperator === 'NotBetween'
    ) ? ''
      : 'block';

    return (
      <Card className="AlertBuilder-Container">
        <Card.Body>
          <Form>
            {this.renderInvalidDefinition()}
            <Row>
              <Col>
                <Form.Group controlId="title">
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    defaultValue={this.state.card.title}
                    onChange={this.handleChange}
                    isInvalid={this.isInvalidField('title')}
                    style={{ height: '38px' }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a title.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="description">
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Description (optional)"
                    value={this.state.card.description || ''}
                    onChange={this.handleChange}
                    isInvalid={this.isInvalidField('description')}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a description.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="reportId" align="left">
                  <Form.Label>Alert Condition</Form.Label>
                  <div className="AlertBuilder-AlertConditionLabel">
                    Alert me when the following condition matches
                  </div>
                  {this.renderReportComponent()}
                </Form.Group>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              {this.renderDateRangeSection()}
            </Row>
            <Row>
              <Col xs={4}>
                <Form.Group controlId="metric" align="left">
                  <Select
                    className={this.isInvalidField('metric') ? 'invalidFiled' : ''}
                    options={metricsOptionsArray}
                    value={metricsOptionsValue}
                    onChange={(value) => {
                      this.handleChange({
                        currentTarget: {
                          id: 'metric',
                          value: value.value.toString()
                        }
                      })
                    }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: this.isInvalidField('metric') ? 'block' : '' }}
                  >
                    Please select a metric.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="metricOperator" align="left">
                  <Select
                    className={this.isInvalidField('metricOperator') ? 'invalidFiled' : ''}
                    options={operatorOptionsArray}
                    value={operatorOptionsValue}
                    onChange={(value) => {
                      this.handleChange({
                        currentTarget: {
                          id: 'metricOperator',
                          value: value.value
                        }
                      })
                    }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: this.isInvalidField('metricOperator') ? 'block' : '' }}
                  >
                    Please select a operator.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Row style={{ marginLeft: 0, display: thresholdRowStyle }}>
                  {this.renderMetricThresholdSection()}
                  {this.renderMetricThresholdDownSection()}
                </Row>
              </Col>
              <div className="buttonSpacer" />
            </Row>
            <div id="dynamicInput">
              {this.state.card.get('selectedColumns').map((selectedColumn, i) => (
                // eslint-disable-next-line
                <Row key={i}>
                  <Col xs={4}>
                    <Form.Group controlId="dimension" align="left">
                      <Select
                        className={
                          this.isInvalidFieldPath(
                            ['form', 'fields', 'dimensions', i, 'dimension', 'isInvalid']
                          ) ? 'invalidFiled'
                            : ''
                        }
                        options={this.buildDimensionDropdown(i)}
                        value={this.buildDimensionDropdownValue(selectedColumn)}
                        onChange={(value) => {
                          this.handleAddColumnChange(i, {
                            currentTarget: {
                              id: 'dimension',
                              value: value.value,
                              label: value.label
                            }
                          })
                        }}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: this.isInvalidFieldPath(
                          ['form', 'fields', 'dimensions', i, 'dimension', 'isInvalid']
                        ) ? 'block' : '' }}
                      >
                        Please select a dimension.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="dimensionOperator" align="left">
                      <Select
                        className={
                          this.isInvalidFieldPath(
                            ['form', 'fields', 'dimensions', i, 'dimensionOperator', 'isInvalid']
                          ) ? 'invalidFiled'
                            : ''
                        }
                        options={this.buildDimensionOperatorDropdown()}
                        value={this.buildDimensionOperatorDropdownValue(selectedColumn)}
                        onChange={(value) => {
                          this.handleAddColumnChange(i, {
                            currentTarget: {
                              id: 'dimensionOperator',
                              value: value.value
                            }
                          })
                        }}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: this.isInvalidFieldPath(
                          ['form', 'fields', 'dimensions', i, 'dimensionOperator', 'isInvalid']
                        ) ? 'block' : '' }}
                      >
                        Please select a operator.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="dimensionThreshold">
                      <Form.Control
                        type="text"
                        placeholder="Value"
                        style={{ height: '38px' }}
                        onChange={this.handleAddColumnChange.bind(this, i)}
                        value={this.buildDimensionThresholdValue(selectedColumn)}
                        isInvalid={
                          this.state.schema.getIn(['form', 'validated'])
                          && this.state.schema.getIn(['form', 'fields', 'dimensions', i, 'dimensionThreshold', 'isInvalid'])
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a dimension value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <ButtonGroup
                    className="mr-2 AlertsTable-ButtonGroup"
                    aria-label="Second group"
                  >
                    <div
                      className="AlertsTable-ActionButton"
                      onClick={this.removeInput.bind(this, selectedColumn, i)}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          (
                            <Tooltip
                              id="tooltip-auto"
                              style={{ fontSize: 11, opacity: 0.8 }}
                            >
                              Delete Column
                            </Tooltip>
                          )
                        }
                      >
                        <i className="fa fa-trash"></i>
                      </OverlayTrigger>
                    </div>
                  </ButtonGroup>
                </Row>
              ))}
            </div>
            <Row>
              <Col>
                {this.addConditionButton()}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="alert_frequency" align="left">
                  <Form.Label>Alert Frequency</Form.Label>
                  <Select
                    className={this.isInvalidField('alert_frequency') ? 'invalidFiled' : ''}
                    options={frequencyOptionsArray}
                    value={frequencyOptionsValue}
                    onChange={(value) => {
                      this.handleAlertFrequencyChange({
                        currentTarget: {
                          value: value.value
                        }
                      })
                    }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: this.isInvalidField('alert_frequency') ? 'block' : '' }}
                  >
                    Please select frequency.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  controlId="alert_frequencyUnit"
                  style={{ display: alert_frequencyDisplayStyle }}
                  align="left"
                >
                  <Form.Label>&nbsp;</Form.Label>
                  <Select
                    className={this.isInvalidField('alert_frequencyUnit') ? 'invalidFiled' : ''}
                    options={subFrecuencyOptionsArray}
                    value={subFrecuencyOptionsValue}
                    onChange={(value) => {
                      this.handleAlertFrequencyUnitChange({
                        currentTarget: {
                          value: value.value
                        },
                        target: {
                          value: value.value
                        }
                      })
                    }}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: this.isInvalidField('alert_frequencyUnit') ? 'block' : '' }}
                  >
                    Please select an option.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {this.renderHourlySection()}
            <Row>
              <Col>
                <Form.Group controlId="notification_group_ids" align="left">
                  <Form.Label>Notification Group</Form.Label>
                  <MultiSelect
                    value={this.state.card.notification_group_ids.toJS()}
                    options={notificationGroupsOptions.toJS()}
                    onChange={(value) => {
                      this.handleChange({
                        currentTarget: {
                          id: 'notification_group_ids',
                          value
                        }
                      })
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please select a notification group.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Link
                  to={{
                    pathname: '/NotificationGroupBuilder',
                    tempCard: this.state.card,
                    tempSchema: this.state.schema
                  }}
                  onClick={this.onSetTempFormStatus.bind(null, true)}
                  className="add-notification-group btn btn-secondary pull-left"
                  role="button"
                >
                  + Add Notification Group
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="enabled">
                  <Form.Check
                    type="checkbox"
                    label="Enable Alert"
                    onChange={this.toogleChange}
                    checked={this.state.card.enabled}
                    className={enabledClassNane}
                  />
                </Form.Group>
                <Form.Row className="Form-ButtonsContainer">
                  <Col>
                    <Link
                      to="/home"
                      onClick={this.onSetTempFormStatus.bind(null, false)}
                      className="btn btn-cancel"
                    >
                      Cancel
                    </Link>
                  </Col>
                  <Col>
                    <Link
                      to="#"
                      onClick={this.onAddCard}
                      className="btn btn-confirm"
                      role="button"
                      style={this.state.disableSubmit ? { pointerEvents: 'none' } : null}
                    >
                      Save
                    </Link>
                  </Col>
                </Form.Row>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertBuilderComponent);
