import Immutable from 'immutable';
import {
  SET_TOKENS,
  FETCH_PROFILE,
  LOGOUT_USER
} from '../actions/profileActions';

const sessionStorageTokens = JSON.parse(localStorage.getItem('t'));
// {
//   csrf: 'vkBobVolGL9G7IwId+d3lDItPQltewzmFCvMglAVwB0=',
//   api: '3b022f48-7a2c-4d61-8cae-c1516fb15c50',
//   legacyApiUrl: 'http://localhost:9292'
// }
/* eslint-disable */
const tokens = sessionStorageTokens
  ? sessionStorageTokens
  : { csrf: false, api: false, legacyApiUrl: false, staqAppUrl: false };
/* eslint-enable */

const sessionStorageProfile = JSON.parse(localStorage.getItem('p'));

/* eslint-disable */
const sessionProfile = sessionStorageProfile
  ? sessionStorageProfile
  : { name: '', email: '', role: '' };
/* eslint-enable */

const initialState = Immutable.fromJS({
  profile: {
    ...sessionProfile,
    tokens
  }
});

const setTokens = function (state, action) {
  localStorage.setItem('t', JSON.stringify(action.payload));
  return state.setIn(['profile', 'tokens'], action.payload);
};

const setProfile = function (state, action) {
  const profile = action.payload;
  localStorage.setItem(
    'p',
    JSON.stringify({
      name: profile.getIn(['user', 'name']),
      email: profile.getIn(['user', 'email']),
      role: profile.getIn(['role', 'name'])
    })
  );
  return state
    .setIn(['profile', 'name'], profile.getIn(['user', 'name']))
    .setIn(['profile', 'email'], profile.getIn(['user', 'email']))
    .setIn(['profile', 'role'], profile.getIn(['role', 'name']));
};

const logout = function (state) {
  localStorage.clear();
  return state
    .setIn(['profile', 'name'], false)
    .setIn(['profile', 'email'], false)
    .setIn(['profile', 'role'], false)
    .setIn(['profile', 'tokens', 'csrf'], false)
    .setIn(['profile', 'tokens', 'api'], false)
    .setIn(['profile', 'tokens', 'legacyApiUrl'], false)
    .setIn(['profile', 'tokens', 'staqAppUrl'], false);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKENS:
      return setTokens(state, action);
    case FETCH_PROFILE:
      return setProfile(state, action);
    case LOGOUT_USER:
      return logout(state);
    default:
      return state;
  }
};
